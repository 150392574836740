import CheckIcon from '@mui/icons-material/Check'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, Button, Grow } from '@mui/material'
import React, { useState } from 'react'
import { DefaultTypography } from '../../../components/typography/default-typography'
import { PageCenter } from '../../../core/enum/help-center/helpCenterEnum'
import { HelperCenterMainDialog } from '../../support/components/HelpCenterMainDialog'

export function MissingInfoCode(): React.JSX.Element {
  const [open, setOpen] = React.useState(false)
  const [actualPage, setActualPage] = useState<PageCenter>(PageCenter.CENTRE_AIDE)

  function handleClickOpen(): void {
    setOpen(true)
  }

  function handleClose(): void {
    setOpen(false)
    setActualPage(PageCenter.CENTRE_AIDE)
  }

  function updateActualPage(toChange: PageCenter): void {
    setActualPage(toChange)
  }
  return (
    <Grow in>
      <Box
        display='flex'
        justifyContent='center'
        boxShadow='2px 4px 20px 0px rgba(0, 0, 0, 0.05)'
        flexDirection='column'
        sx={{ backgroundColor: 'white', borderRadius: '25px', p: 5, gap: 4 }}>
        <Box>
          <WarningAmberIcon htmlColor='red' />
        </Box>
        <Box>
          <DefaultTypography
            label='Vous devez renseigner au minimum les codes ACV Time To Beem dans les objets modélisés de la maquette numérique avant
        import.'
          />
        </Box>
        <Box>
          <DefaultTypography label="C'est simple et rapide, laissez-vous guider !" fontWeight={0} />
        </Box>
        <Button variant='contained' onClick={handleClickOpen}>
          Comment renseigner ces codes ?
        </Button>

        <Box display='flex' flexDirection='column' p={2} sx={{ background: '#E5FFF4', borderRadius: 1 }}>
          <Box display='flex' alignItems='center' gap={1}>
            <CheckIcon sx={{ color: '#0BE2A0' }} />
            <DefaultTypography label='Les codes ACV' />
          </Box>
          <Box display='flex' alignItems='center' gap={1}>
            <CheckIcon sx={{ color: '#BDBDBD' }} />
            <DefaultTypography label='Les descriptions des codes ACV' />
          </Box>
          <Box display='flex' alignItems='center' gap={1}>
            <CheckIcon sx={{ color: '#BDBDBD' }} />
            <DefaultTypography label='Les différents usages' />
          </Box>
        </Box>
        <HelperCenterMainDialog
          open={open}
          handleClose={handleClose}
          actualPage={actualPage}
          updateActualPage={updateActualPage}
        />
      </Box>
    </Grow>
  )
}
