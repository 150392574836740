import { Auth } from 'aws-amplify'
import React, { useCallback, useMemo, useRef } from 'react'
import { Children } from '../../../components/miscellianous/children'
import {
  completePasswordChallenge,
  deleteAllCookies,
  getCognitoId,
  getCognitoUserIdFromStorage,
  getCookieValue,
  getJwtTokenFromStorage,
  signIn,
} from '../../services/authentication-service'

export const TokenContext = React.createContext<TokenStore>({} as TokenStore)

type ResolveFunction = (value: string | PromiseLike<string | undefined> | undefined) => void

export function TokenContextProvider({ children }: Children): React.JSX.Element {
  const initialCognitoId: string | undefined = useMemo(() => getCognitoUserIdFromStorage(), [])
  const initialToken: string | undefined = useMemo(() => getJwtTokenFromStorage(), [])

  const tokenRef = useRef<string | undefined>(initialToken)
  const cognitoUserIdRef = useRef<string | undefined>(initialCognitoId)
  const isLoadingRef = useRef<boolean>(false)
  const pendingRequest = React.useRef<ResolveFunction[]>([]) // This is a queue, so there is no need to send multiple refresh at the same time

  const setTokenId = useCallback((newToken: string | undefined): void => {
    if (newToken) {
      tokenRef.current = newToken
      localStorage.setItem('idToken', newToken)
    } else {
      tokenRef.current = undefined
      localStorage.removeItem('idToken')
    }
  }, [])

  const setCognitoUserId = useCallback((newCognitoUserId: string | undefined): void => {
    if (newCognitoUserId) {
      cognitoUserIdRef.current = newCognitoUserId
      localStorage.setItem('cognitoUserId', newCognitoUserId)
    } else {
      cognitoUserIdRef.current = undefined
      localStorage.removeItem('cognitoUserId')
    }
  }, [])

  const unsetCookieAndIds = useCallback(async () => {
    // Following line invalidates cookies with setting expired date to yesterday
    await Auth.signOut({ global: true })
      .catch((error) => {
        console.error('error signing out: ', error)
      })
      .finally(() => {
        deleteAllCookies()
        setTokenId(undefined)
        setCognitoUserId(undefined)
      })
  }, [setCognitoUserId, setTokenId])

  const refreshToken: () => Promise<string | undefined> = useCallback(() => {
    if (!isLoadingRef.current) {
      isLoadingRef.current = true
      return Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(async (userInfos) => {
          console.info('refresh user token')
          if (userInfos?.attributes) {
            const newCognitoId = getCognitoId(userInfos.attributes)
            let newToken: string | undefined
            if (!newCognitoId) {
              console.info('logout car pas de cognito id après le refresh')
              await unsetCookieAndIds()
              newToken = undefined
            } else if (cognitoUserIdRef.current !== newCognitoId) {
              console.info("logout car le précédent user n'est pas le même que l'actuel")
              await unsetCookieAndIds()
              newToken = undefined
            } else {
              console.info('set token')
              newToken = getCookieValue('idToken')
              setTokenId(newToken)
              setCognitoUserId(newCognitoId)
            }
            resolvePendingRequest(newToken)
            return newToken
          } else {
            console.info('logout because no user infos')
            await unsetCookieAndIds()
            resolvePendingRequest(undefined)
            return undefined
          }
        })
        .catch(async (err: Error) => {
          console.info('Refresh error', err)
          await unsetCookieAndIds()
          if (err.message !== 'The user is not authenticated') {
            // When a user is refreshed, he may not be authenticated
            // It is not an error
            resolvePendingRequest(undefined)
            return undefined
          }
          throw err
        })
        .finally(() => {
          isLoadingRef.current = false
        })
    } else {
      // Create a promise and return it + add the 'resolve()' of this promise to the queue of functions to call when the token is refreshed
      return new Promise((resolve) => {
        pendingRequest.current.push(resolve)
      })
    }
  }, [setCognitoUserId, setTokenId, unsetCookieAndIds])

  function resolvePendingRequest(newToken: string | undefined): void {
    pendingRequest.current.forEach((resolve: ResolveFunction) => {
      resolve(newToken)
    })
    pendingRequest.current = []
  }

  const getTokenFromCognito = useCallback(
    (name: string, password: string): Promise<void> =>
      signIn(name, password).then((userInfos: any) => {
        if (userInfos.challengeName === 'NEW_PASSWORD_REQUIRED') {
          completePasswordChallenge(userInfos, password).then((response) => {
            console.info('challenge response: ', response)
            const newToken = getCookieValue('idToken')
            setTokenId(newToken)
            console.info('response.challengeParam : ', response.challengeParam)
            const attributes = response.challengeParam.userAttributes
            const newCognitoUserId = getCognitoId(attributes())
            setCognitoUserId(newCognitoUserId)
          })
        } else {
          const newToken = getCookieValue('idToken')
          setTokenId(newToken)
          const newCognitoUserId = getCognitoId(userInfos?.attributes)
          setCognitoUserId(newCognitoUserId)
        }
      }),
    [setCognitoUserId, setTokenId]
  )

  const tokenStore: TokenStore = useMemo(
    () => ({ tokenRef, cognitoUserIdRef, unsetCookieAndUserId: unsetCookieAndIds, refreshToken, getTokenFromCognito }),
    [tokenRef, cognitoUserIdRef, unsetCookieAndIds, refreshToken, getTokenFromCognito]
  )
  return <TokenContext.Provider value={tokenStore}>{children}</TokenContext.Provider>
}

export type TokenStore = {
  tokenRef: React.MutableRefObject<string | undefined>
  cognitoUserIdRef: React.MutableRefObject<string | undefined>
  unsetCookieAndUserId(): Promise<void>
  refreshToken(): Promise<string | undefined>
  getTokenFromCognito(name: string, password: string): Promise<void>
}
