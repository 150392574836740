import { Box, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CustomBottomNavigation } from '../../components/buttons/navigate-button/CustomBottomNavigation'
import { pagesUrl } from '../../core/appConstants'
import { BSProjectContext } from '../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSProjectListContext } from '../../core/context/beem-shot/BSProject/BSProjectListContext'
import { BSVariantContext } from '../../core/context/beem-shot/BSVariant/BSVariantContext'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../core/context/side-bar/side-bar-context'
import { BSProjectForm } from './components/BSProjectForm'
import { BSProjectListContainer } from './components/BSProjectListContainer'
import { BSVariantForm } from './components/BSVariant/BSVariantForm'
import { TokenContext } from '../../core/context/user/token-context'

export function BSHomePage(): React.JSX.Element {
  const { refreshToken } = useContext(TokenContext)
  const location = useLocation()
  const { setSelectedVariant, setBsVariants, selectedVariant } = useContext(BSVariantContext)
  const { fetchBsProjectCard } = useContext(BSProjectListContext)
  const { setBsProject } = useContext(BSProjectContext)
  const { isLoading } = useContext(BSProjectListContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl } = useContext(AppNavBarContext)

  const [openCreateProjectForm, setOpenCreateProjectForm] = useState<boolean>(false)
  const [openCreateVariantForm, setOpenCreateVariantForm] = useState<boolean>(false)

  useEffect(() => {
    if (!openCreateProjectForm && !openCreateVariantForm) {
      setSelectedVariant(undefined)
      setBsVariants([])
      setBsProject(undefined)
    }
  }, [setBsProject, setSelectedVariant, openCreateProjectForm, openCreateVariantForm, fetchBsProjectCard, setBsVariants])

  useEffect(() => {
    setShowAppSide(true)
    setTitle('Beem Shot')
    setPreviousUrl(pagesUrl.HOME)
  }, [setShowAppSide, location, setTitle, setPreviousUrl])

  function handleClose(openCreateVariant?: boolean): void {
    setOpenCreateProjectForm(false)
    if (openCreateVariant) {
      setOpenCreateVariantForm(true)
    } else {
      setBsProject(undefined)
    }
  }

  function handleCloseVariant(): void {
    setOpenCreateVariantForm(false)
    fetchBsProjectCard()
  }

  return (
    <Box display='flex' flexDirection='column' height='78vh'>
      {isLoading ? (
        <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <BSProjectListContainer />
      )}
      <CustomBottomNavigation actionLabel='Creer un projet beem shot' actionButton={() => setOpenCreateProjectForm(true)} />
      <BSProjectForm open={openCreateProjectForm} handleClose={handleClose} />
      <BSVariantForm open={openCreateVariantForm} handleClose={handleCloseVariant} bsVariant={selectedVariant} />
    </Box>
  )
}
