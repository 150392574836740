import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ValidateButton } from '../../../../components/buttons/validate-button/validate-button'
import { CustomTooltipDescription } from '../../../../components/tooltip/CustomTooltipDescription'
import { pagesUrl } from '../../../../core/appConstants'
import { BSProjectContext, isBSMainUser } from '../../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { BSVariant } from '../../../../core/dto/beem-shot/BSVariant/BSVariant'
import { resolveUrl } from '../../../../core/services/http-service'
import { BSVariantForm } from './BSVariantForm'

interface IProps {
  open: boolean
  projectName: string | undefined
  bsProjectId: string | undefined
  isEditMode?: boolean

  handleClose(cancelAction?: boolean): void
}

const Transition = React.forwardRef<
  unknown,
  TransitionProps & {
    children: React.ReactElement<any, any>
  }
>((props, ref) => <Slide direction='up' ref={ref} {...props} />)

Transition.displayName = 'Transition'

export function BSSelectVariantDialog({
  open,
  handleClose,
  projectName,
  bsProjectId,
  isEditMode = false,
}: Readonly<IProps>): React.JSX.Element {
  const { bsVariants, selectedVariant, setSelectedVariant } = useContext(BSVariantContext)

  const { bsProject } = useContext(BSProjectContext)

  const [chosenVariant, setChosenVariant] = useState<BSVariant>()
  const [openCreateVariantForm, setOpenCreateVariantForm] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(isEditMode)

  const navigate = useNavigate()

  useEffect(() => {
    if (selectedVariant) {
      setChosenVariant(selectedVariant)
    } else if (bsVariants.length !== 0) {
      setChosenVariant(bsVariants[0])
    }
  }, [bsVariants, selectedVariant])

  function selectVariant(): void {
    if (chosenVariant) {
      setSelectedVariant(chosenVariant)
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECT, [bsProjectId, chosenVariant?.id]))
      handleClose()
    }
  }

  function handleCloseVariant(): void {
    setOpenCreateVariantForm(false)
  }

  return (
    <>
      <Dialog open={open} maxWidth='sm' fullWidth onClose={() => handleClose(true)} TransitionComponent={Transition}>
        <DialogTitle display='flex' justifyContent='space-between' alignItems='center'>
          {projectName}
          <IconButton edge='start' color='inherit' onClick={() => handleClose(true)} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='subtitle1'>Choisir une variante</Typography>
            </Grid>
            <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 300 }}>
              <Box p={1} sx={{ height: '100%', overflowY: 'auto' }}>
                {bsVariants?.map((bsVariant) => (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    key={bsVariant.id}
                    onClick={() => {
                      setChosenVariant(bsVariant)
                    }}
                    p={1}
                    border={3}
                    borderRadius={2}
                    sx={{
                      gap: 2,
                      my: 1,
                      background: '#F5F8F8',
                      borderColor: bsVariant === chosenVariant ? '#0BE2A0' : '#e4ebeb',
                    }}>
                    {bsVariant.name}

                    {isBSMainUser(bsProject) && isEditMode && (
                      <Box>
                        <IconButton
                          aria-label='edit'
                          color='inherit'
                          onClick={() => {
                            setChosenVariant(bsVariant)
                            handleClose()
                            setIsEdit(true)
                            setOpenCreateVariantForm(true)
                          }}>
                          <ModeEditIcon />
                        </IconButton>
                      </Box>
                    )}
                    {!isEditMode && bsVariant?.description && (
                      <CustomTooltipDescription description='Description du calcul' title={bsVariant?.description} />
                    )}
                  </Box>
                ))}
                {isBSMainUser(bsProject) && (
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClose()
                      setIsEdit(false)
                      setOpenCreateVariantForm(true)
                    }}>
                    <IconButton aria-label='edit' color='inherit'>
                      <AddIcon />
                    </IconButton>
                    Créer une variante
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ValidateButton label='Selectionner' action={selectVariant} />
        </DialogActions>
      </Dialog>

      <BSVariantForm
        open={openCreateVariantForm}
        handleClose={handleCloseVariant}
        isUpdateMode={isEdit}
        bsVariant={chosenVariant}
      />
    </>
  )
}
