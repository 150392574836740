import { Box, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CustomBottomNavigation } from '../../components/buttons/navigate-button/CustomBottomNavigation'
import { pagesUrl } from '../../core/appConstants'
import { RseeProjectContext } from '../../core/context/beem-pilot/rsee/rsee-project-context'
import { RseeProjectListContext } from '../../core/context/beem-pilot/rsee/rsee-project-list-context'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../core/context/side-bar/side-bar-context'
import { RseeProjectForm } from './rseeProject/component/RseeProjectForm'
import { RseeProjectListContainer } from './rseeProject/component/RseeProjectListContainer'

export function RseeHomePage(): React.JSX.Element {
  const location = useLocation()
  const { isLoading } = useContext(RseeProjectListContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setPagination } = useContext(AppNavBarContext)
  const { setRseeProject } = useContext(RseeProjectContext)

  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false)

  const [haveBeenLoaded, setHaveBeenLoaded] = useState<boolean>(false)

  useEffect(() => {
    setShowAppSide(true)
    setTitle('Beem Pilot')
    setPreviousUrl(pagesUrl.HOME)
    setPagination(['Tableau de bord', 'Beem Pilot'])
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setPagination])

  useEffect(() => {
    setRseeProject(undefined)
  }, [setRseeProject])

  useEffect(() => {
    if (!isLoading && !haveBeenLoaded) {
      setHaveBeenLoaded(true)
    }
  }, [haveBeenLoaded, isLoading])

  function handleClose(): void {
    setOpenCreateForm(false)
  }

  return (
    <Box display='flex' flexDirection='column' height='78vh'>
      {!haveBeenLoaded ? (
        <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <RseeProjectListContainer />
      )}
      <CustomBottomNavigation actionLabel='Creer un projet Beem Pilot' actionButton={() => setOpenCreateForm(true)} />
      <RseeProjectForm open={openCreateForm} handleClose={handleClose} />
    </Box>
  )
}
