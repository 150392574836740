import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import CancelButton from '../../../../components/buttons/cancel-button/cancel-button'
import { ValidateButton } from '../../../../components/buttons/validate-button/validate-button'
import { NumberInput } from '../../../../components/inputs/number-input/number-input'
import { SuccessContext } from '../../../../components/layout/success-snackbar'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { TextInputTypography } from '../../../../components/typography/textInputTypography'
import { appConstants } from '../../../../core/appConstants'
import { BSMaterialResultContext } from '../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext'
import { BSVariantContext } from '../../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { BSMaterialResult } from '../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import IniesRecord from '../../../../core/dto/material/inies-record'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { DeclarationTypeEnum } from '../../../../core/enum/declarationTypeEnum'
import { FunctionalUnitEnum } from '../../../../core/enum/functionalUnitEnum'
import { fromIniesId, toLabel } from '../../../../core/enum/unitEnum'
import { formatToFrench } from '../../../../core/services/date-service'
import { getDeclarationTypeLabel } from '../../../../core/services/declaration-type-service'
import { stringToNumber } from '../../../../core/services/helper-service'

type IProps = {
  selectedRow: IniesRecord | MaterialRecord
  getTypologyBackground: (typologie: DeclarationTypeEnum) => string
  getTypologyTextColor: (typologie: DeclarationTypeEnum) => string
  handleCloseMaterialCardModal: () => void
  handleClose: () => void
  selectedBsMaterialResult: BSMaterialResult | undefined
}

export function BSMaterialCardInfo({
  selectedRow,
  getTypologyBackground,
  handleCloseMaterialCardModal,
  handleClose,
  getTypologyTextColor,
  selectedBsMaterialResult,
}: Readonly<IProps>): React.JSX.Element {
  const { addOverridenMaterial } = useContext(BSMaterialResultContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)

  const [isSubmitting, setIsSubmiting] = useState<boolean>(false)
  const [openWarningFunctionnalUnit, setOpenWarningFunctionnalUnit] = useState<boolean>(false)
  const [actualQuantities, setActualQuantities] = useState<number>(selectedBsMaterialResult?.quantity || 0)

  const successfullyAddMaterialLabel = 'Le materiau a bien été modifié !'

  function getUrl(): string {
    return `${appConstants.web.baseInie}?id=${selectedRow?.iniesId}`
  }

  function getFunctionalUnit(): FunctionalUnitEnum {
    return FunctionalUnitEnum[selectedRow?.functionalUnit as keyof typeof FunctionalUnitEnum]
  }

  function getAllParameters(): string {
    let chaineConcatenee = `${selectedRow.description || ''}`
    if (selectedRow instanceof MaterialRecord && selectedRow.parameters.length > 0) {
      chaineConcatenee += '<br/><br/><b> Paramètres : </b> <ul>'
      selectedRow.parameters.forEach((chaine) => {
        chaineConcatenee += ` <li><b> ${chaine.name} : </b>  ${chaine.value} ${toLabel(
          fromIniesId(chaine.paramUnitId)
        )}  </li>
      `
        return chaine
      })
      chaineConcatenee += '</ul>'
    }
    return chaineConcatenee
  }

  function addMaterial(): Promise<void> {
    if (selectedBsMaterialResult?.id && selectedVariant?.id) {
      setIsSubmiting(true)
      return addOverridenMaterial(selectedVariant?.id, selectedBsMaterialResult?.id, selectedRow.iniesId, actualQuantities)
        .then(() => {
          openSuccessSnackbar(successfullyAddMaterialLabel)
          setOpenWarningFunctionnalUnit(false)
          handleClose()
        })
        .finally(() => setIsSubmiting(false))
    }
    return Promise.resolve()
  }

  const functionalUnit: FunctionalUnitEnum =
    FunctionalUnitEnum[selectedRow.functionalUnit as unknown as keyof typeof FunctionalUnitEnum]

  return (
    <>
      <Card sx={{ width: 1100, background: '#ffffff', borderRadius: 4 }}>
        <CardContent>
          <Box component='form' display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography
                component='span'
                sx={{
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {selectedRow.fdesName}
              </Typography>

              <IconButton sx={{ marginLeft: 'auto' }} onClick={handleCloseMaterialCardModal}>
                <CloseOutlinedIcon />
              </IconButton>
            </Box>

            <Box borderRadius={4}>
              <Typography
                fontWeight={600}
                sx={{
                  display: 'inline-block',
                  backgroundColor: getTypologyBackground(selectedRow?.declarationType),
                  color: getTypologyTextColor(selectedRow?.declarationType),
                  padding: 0.8,
                  borderRadius: 3,
                  fontSize: 12,
                }}>
                {getDeclarationTypeLabel(selectedRow?.declarationType)}
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              py={1}
              alignItems='center'
              alignContent='center'
              columnGap={0.5}
              justifyItems='center'>
              <DefaultTypography label='Modifiée le ' color='#070F27' fontWeight={400} fontSize='11px' lineHeight='16.5px' />
              <DefaultTypography
                label={
                  selectedRow instanceof IniesRecord
                    ? ` ${formatToFrench(selectedRow?.lastIniesUpdate)} depuis la base INIES.`
                    : ` ${formatToFrench(selectedRow?.lastModifiedDate)} par ${selectedRow?.lastModifiedUserName}.`
                }
                color='#070F27'
                fontWeight={500}
                fontSize='11px'
                lineHeight='16.5px'
              />
            </Box>

            <Grid
              container
              rowGap={2}
              flexGrow={1}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              py={2}>
              <Grid
                item
                xs={2.8}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
                <DefaultTypography
                  label='Total impact selon RE2020'
                  fontSize='11px'
                  lineHeight='26.5px'
                  fontWeight={400}
                  color='#8398A6'
                />
                <DefaultTypography
                  label={`${(selectedRow?.re2020CarbonImpact ?? 0).toFixed(2)} kgeqCO2/${getFunctionalUnit()}`}
                  fontSize='14px'
                  lineHeight='24px'
                  fontWeight={500}
                />
              </Grid>

              <Grid
                item
                xs={2.8}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
                <DefaultTypography
                  label='  Unité fonctionnelle'
                  fontSize='11px'
                  lineHeight='26.5px'
                  fontWeight={400}
                  color='#8398A6'
                />
                <DefaultTypography label={functionalUnit} fontSize='14px' lineHeight='24px' fontWeight={500} />
              </Grid>

              <Grid
                item
                xs={2.8}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
                <DefaultTypography
                  label='Durée de vie'
                  fontSize='11px'
                  lineHeight='26.5px'
                  fontWeight={400}
                  color='#8398A6'
                />
                <DefaultTypography
                  label={selectedRow.referenceLifeTime.toString()}
                  fontSize='14px'
                  lineHeight='24px'
                  fontWeight={500}
                />
              </Grid>

              <Grid
                item
                xs={12}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
                <DefaultTypography
                  label='Caractéristiques'
                  fontSize='11px'
                  lineHeight='26.5px'
                  fontWeight={400}
                  color='#8398A6'
                />
                <Typography
                  variant='body1'
                  dangerouslySetInnerHTML={{ __html: getAllParameters() }}
                  fontSize='14px'
                  lineHeight='24px'
                  fontWeight={500}
                />
              </Grid>

              {selectedRow instanceof IniesRecord && (
                <Grid
                  item
                  xs={12}
                  display='flex'
                  flexDirection='column'
                  sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
                  <DefaultTypography
                    label='Organisme déclarant'
                    fontSize='11px'
                    lineHeight='26.5px'
                    fontWeight={400}
                    color='#8398A6'
                  />
                  <DefaultTypography label={`${selectedRow.responsibleOrganism}`} fontSize='14px' lineHeight='24px' />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' pt={1}>
            <Typography
              component='a'
              href={getUrl()}
              target='_blank'
              rel='noopener noreferrer'
              fontSize='14px'
              fontWeight={400}
              sx={{ color: '#070F27' }}>
              Consulter la base inies
            </Typography>

            <Box display='flex' flexDirection='row'>
              <Box sx={{ px: 1 }}>
                <CancelButton action={() => handleCloseMaterialCardModal()} label='Annuler' />
              </Box>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <ValidateButton
                  action={() => {
                    if (selectedRow.functionalUnit !== selectedBsMaterialResult?.ficheUnite) {
                      setOpenWarningFunctionnalUnit(true)
                    } else {
                      addMaterial()
                    }
                  }}
                  label='Utiliser'
                  isSubmiting={isSubmitting}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Dialog maxWidth='md' fullWidth open={openWarningFunctionnalUnit} onClose={handleClose}>
        <DialogTitle>Êtes-vous sûr de vouloir affecter cette fiche ?</DialogTitle>
        <DialogContentText color='black' px={2}>
          <Typography variant='body2' pb={1}>
            Vous essayez d'affecter une fiche avec une unité différente. Veuillez modifier la quantité actuel si nécéssaire .
          </Typography>

          <Grid display='flex' flexDirection='row' container rowGap={1}>
            <Grid item xs={2} display='flex' flexDirection='column'>
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: 600,
                  lineHeight: '26.5px',
                  textAlign: 'left',
                }}>
                Unité:
              </Typography>
            </Grid>

            <Grid
              item
              xs={5}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
              <TextInputTypography label='Unité actuelle' />
              <DefaultTypography
                label={selectedBsMaterialResult?.ficheUniteLabel ?? ''}
                fontSize='12px'
                lineHeight='18px'
                fontWeight={400}
              />
            </Grid>

            <Grid
              item
              xs={5}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
              <TextInputTypography label='Unité de la fiche' />
              <DefaultTypography label={getFunctionalUnit() ?? ''} fontSize='12px' lineHeight='18px' fontWeight={400} />
            </Grid>

            {selectedBsMaterialResult?.extractedQuantities && (
              <Grid display='flex' flexDirection='row' container rowGap={1}>
                <Grid item xs={2} display='flex' flexDirection='column'>
                  <Typography
                    sx={{
                      fontSize: '11px',
                      fontWeight: 600,
                      lineHeight: '26.5px',
                      textAlign: 'left',
                    }}>
                    Quantité extraites:
                  </Typography>
                </Grid>

                <Grid display='flex' container xs={10} rowGap={1}>
                  <Grid
                    item
                    xs={6}
                    display='flex'
                    flexDirection='column'
                    sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                    <TextInputTypography label='Hauteur (m)' />
                    <DefaultTypography
                      label={selectedBsMaterialResult?.extractedQuantities.height?.toFixed(1).toString() ?? ''}
                      fontSize='12px'
                      lineHeight='18px'
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    display='flex'
                    flexDirection='column'
                    sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                    <TextInputTypography label='Nombre' />
                    <DefaultTypography
                      label={selectedBsMaterialResult?.extractedQuantities.number?.toFixed(1).toString() ?? ''}
                      fontSize='12px'
                      lineHeight='18px'
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    display='flex'
                    flexDirection='column'
                    sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                    <TextInputTypography label='Longueur (m)' />
                    <DefaultTypography
                      label={selectedBsMaterialResult?.extractedQuantities.length?.toFixed(1).toString() ?? ''}
                      fontSize='12px'
                      lineHeight='18px'
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    display='flex'
                    flexDirection='column'
                    sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                    <TextInputTypography label='Surface (m²)' />
                    <DefaultTypography
                      label={selectedBsMaterialResult?.extractedQuantities.surface?.toFixed(1).toString() ?? ''}
                      fontSize='12px'
                      lineHeight='18px'
                      fontWeight={400}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Grid display='flex' flexDirection='row' container>
            <Grid item xs={2} display='flex' flexDirection='column'>
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: 600,
                  lineHeight: '26.5px',
                  textAlign: 'left',
                }}>
                Modifier la quantité :
              </Typography>
            </Grid>

            <Grid item xs={10} display='flex' flexDirection='column'>
              <NumberInput
                id='Quantité'
                label='Quantité actuelle'
                value={actualQuantities ?? 0}
                fullWidth={false}
                decimalScale={1}
                variant='outlined'
                handleChange={(value) => {
                  if (selectedBsMaterialResult) {
                    setActualQuantities(stringToNumber(value))
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
        <DialogActions>
          <Box sx={{ px: 1 }}>
            <CancelButton action={handleClose} label='Annuler' />
          </Box>
          <ValidateButton
            action={() => {
              setOpenWarningFunctionnalUnit(false)
              addMaterial()
            }}
            label='Confirmer'
            isSubmiting={isSubmitting}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
