import {string} from 'prop-types'
import * as WebIFC from 'web-ifc'
import {IFCManager} from 'web-ifc-three/IFC/components/IFCManager'
import {IfcPropertyHelper} from '../../components/ifc-displayer/helpers/IfcPropertyHelper'
import PsetAcv from '../dto/pset-acv'
import {QuantityKey} from '../dto/quantity'
import {baseQuantityEnum} from '../enum/baseQuantityEnum'
import {CotesEnum} from '../enum/ifcEnum/cotesEnum'
import {psetPropertyFileEnum} from '../enum/psetPropertyFileEnum'
import {equalIgnoreCase, stringToNumber} from './helper-service'

const numberRegex = /\d*/g

type KeyValue = {
  [key: string]: any
}

type KeyValueArray = {
  [key: string]: any[]
}

type CodeToPsetAcv = {
  [key: string]: PsetAcv[] | undefined
}

export type ParsedValues = {
  psetAcvs: PsetAcv[]
  typesToElementIdsMap: Map<number, number[]>
}

function affectIfNotZeroOrNull(psetAcv: PsetAcv, quantityKey: QuantityKey, value: number | undefined): void {
  if (value) {
    psetAcv.quantity[quantityKey] = value
  }
}

function selectAreaBetweenNetAndGross(netArea: number, grossArea: number): number {
  // Usually we should always use the netArea. But there is a bug in some IFC from revit, where netArea is incorrect and greater than gross area.
  // It should be impossible. netArea should always be lower. So in that case, it's better to use the gross area
  let surface: number
  if (netArea !== 0 && netArea <= grossArea ) {
    surface = netArea
  } else {
    surface = grossArea
  }
  return surface
}

function coteToNumber(cote: any): number {
  return cote instanceof string ?  stringToNumber(cote as string) : cote as number
}

export async function parseBim(
  modelId: number,
  ifcManager: IFCManager,
  setProgress: (progress: number) => void
): Promise<ParsedValues> {
  const start = Date.now()
  const parsedValues: ParsedValues = { psetAcvs: [], typesToElementIdsMap: new Map() } as ParsedValues
  const psetAcvExtracted: KeyValue = {} // keys are pset TTB_PSET_ACV ids, values is the values of this pset
  const coteExtracted: KeyValue = {} // keys are pset 'cotes' ids, values is the values of this pset
  const coteExtractedPerItemId: KeyValue = {} // keys are item ids, values 'cotes' from type
  const relExtracted: KeyValueArray = {} // keys are the item ids, values is a list of the keys of the pset linked to this item
  const itemIdsExtracted: string[] = [] // List of ids of items (like IFCWALL)
  const typesExtracted: KeyValue = {} // keys are the items types ids, like IFCWALLTYPE id, values are the item type
  const baseQuantitiesExtracted: KeyValue = {} // keys are pset "BASE_QUANTITIES" ids, values is the values of this pset
  const typeRelationsExtracted: KeyValueArray = {} // keys are the ids of IFC types (like IFCWALLTYPE), values is a list of ids of all the pset on these type
  // Some items are an aggregate of multiple items. Its for IFCSTAIR, IFCRAMP, IFCROOF and IFCCURTAINWALL. The parent id link to an invisible item.
  // So to display these items in the viewer, you need the ids of the visible items that compose it.
  // Keys are these items ids. Values is the item ids of the items that compose it
  const aggregatedItemsExtracted: Map<string, number[]> = new Map<string, number[]>()
  const tagToNumber: Map<string, number> = new Map<string, number>()
  const itemIdToTag: Map<string, string> = new Map<string, string>()

  // NominalLength : number | undefined]
  // NominalWidth : number | undefined
  // NominalHeight : number | undefined
  // Height : number | undefined
  // CrossSectionArea : number | undefined
  // OuterSurfaceArea	 : number | undefined
  // TotalSurfaceArea : number | undefined
  // GrossVolume : number | undefined
  // GrossWeight : number | undefined
  // GrossFootprintArea : number | undefined
  // GrossSideAreaLeft : number | undefined
  // GrossSideAreaRight : number | undefined
  // NetVolume : number | undefined
  // NetWeight : number | undefined
  // NetFootprintArea : number | undefined
  // NetSideAreaLeft : number | undefined
  // NetSideAreaRight : number | undefined
  // Diameter : number | undefined
  // CoveredArea : number | undefined
  // Perimeter : number | undefined

  function getTagForItemId(itemId: string): string | undefined {
    return itemIdToTag.get(itemId)
  }

  function fillQuantityFromBaseQuantities(psetAcv: PsetAcv, baseQuantity: any, itemId: string): void {
    const tag: string | undefined = getTagForItemId(itemId)
    let numberOfItemWithTag: number | undefined
    if (tag) {
      numberOfItemWithTag = tagToNumber.get(tag)
    }

    if (baseQuantity[baseQuantityEnum.height]) {
      affectIfNotZeroOrNull(psetAcv, 'height', stringToNumber(baseQuantity[baseQuantityEnum.height].LengthValue.value))
    }
    if (baseQuantity[baseQuantityEnum.length]) {
      affectIfNotZeroOrNull(psetAcv, 'length', stringToNumber(baseQuantity[baseQuantityEnum.length].LengthValue.value))
    }

    if (baseQuantity[baseQuantityEnum.netSideArea]) {
      const netSideArea = stringToNumber(baseQuantity[baseQuantityEnum.netSideArea]?.AreaValue?.value)
      const grossSideArea = stringToNumber(baseQuantity[baseQuantityEnum.grossSideArea]?.AreaValue?.value)
      const surface = selectAreaBetweenNetAndGross(netSideArea, grossSideArea)
      affectIfNotZeroOrNull(psetAcv, 'surface', surface)
    } else if (baseQuantity[baseQuantityEnum.netArea]) {
      const netArea = stringToNumber(baseQuantity[baseQuantityEnum.netArea]?.AreaValue?.value)
      const grossArea = stringToNumber(baseQuantity[baseQuantityEnum.grossArea]?.AreaValue?.value)
      const surface = selectAreaBetweenNetAndGross(netArea, grossArea)
      if (numberOfItemWithTag && numberOfItemWithTag > 1) {
        affectIfNotZeroOrNull(psetAcv, 'surface', surface / numberOfItemWithTag)
      } else {
        affectIfNotZeroOrNull(psetAcv, 'surface', surface)
      }
    } else if (baseQuantity[baseQuantityEnum.area]) {
      affectIfNotZeroOrNull(psetAcv, 'surface', stringToNumber(baseQuantity[baseQuantityEnum.area].AreaValue.value))
    } else if (baseQuantity[baseQuantityEnum.totalSurfaceArea]) {
      affectIfNotZeroOrNull(psetAcv, 'surface', stringToNumber(baseQuantity[baseQuantityEnum.totalSurfaceArea].AreaValue.value))
    } else if (baseQuantity[baseQuantityEnum.grossSideArea]) {
      // GrossSideArea is incorrect if there is a hole in the surface
      // For the "menuiseries intérieures/extérieure", there should never be a hole, so we take it
      // If you take the gross side area, be careful because, the "cote" will not be used. Even if it may have a more precise
      // value. So we don't use it for other elements. Especially since we had a BIM model with very bugged surface in the "cote" for
      // "menuiseries intérieures/extérieure"
      if (
          psetAcv.codeAcv === 'ENV_MEN_FEN' ||
          psetAcv.codeAcv === 'ENV_MEN_POR' ||
          psetAcv.codeAcv === 'ENV_MEN_POR_FEN' ||
          psetAcv.codeAcv === 'AME_INT_POR_TEC' ||
          psetAcv.codeAcv === 'AME_INT_MEN_POR_PAL' ||
          psetAcv.codeAcv === 'AME_INT_MEN_FEN' ||
          psetAcv.codeAcv === 'AME_INT_MEN_POR'
      ) {
        affectIfNotZeroOrNull(psetAcv, 'surface', stringToNumber(baseQuantity[baseQuantityEnum.grossSideArea].AreaValue.value))
      }
    }

    /**  Keep this to remember that width and volume can sometime get extracted from IFC. May be useful later
     *
     // if (quantity[baseQuantityEnum.width]) {
    //   psetAcv.quantity.width = stringToNumber(quantity[baseQuantityEnum.width].LengthValue.value)
    // }
     // if (quantity[baseQuantityEnum.netVolume]) {
    //   psetAcv.quantity.volume = stringToNumber(quantity[baseQuantityEnum.netVolume].VolumeValue.value)
    // }
     */
  }

  function fillQuantityFromCotes(psetAcv: PsetAcv, cotes: any, itemId: string): void {
    // cotes have lower priority than base quantities.
    // Which means, we don't want them to replace surface or length values from base quantities that might have been extracted
    const tag: string | undefined = getTagForItemId(itemId)
    let numberOfItemWithTag: number | undefined
    if (tag) {
      numberOfItemWithTag = tagToNumber.get(tag)
    }

    if (cotes[CotesEnum.Longueur] && (psetAcv.quantity.length === undefined || psetAcv.quantity.length === 0)) {
      const length = coteToNumber(cotes[CotesEnum.Longueur])
      affectIfNotZeroOrNull(psetAcv, 'length', length)
    }

    if (cotes[CotesEnum.Surface] && (psetAcv.quantity.surface === undefined || psetAcv.quantity.surface === 0)) {
      let surface = 0
      if (
        psetAcv.codeAcv === 'ENV_MEN_FEN' ||
        psetAcv.codeAcv === 'ENV_MEN_POR' ||
        psetAcv.codeAcv === 'ENV_MEN_POR_FEN' ||
        psetAcv.codeAcv === 'AME_INT_POR_TEC' ||
        psetAcv.codeAcv === 'AME_INT_MEN_POR_PAL' ||
        psetAcv.codeAcv === 'AME_INT_MEN_FEN' ||
        psetAcv.codeAcv === 'AME_INT_MEN_POR'
      ) {
        // For the "menuiseries intérieures/extérieures" (code: ENV_MEN_FEN/BSItem: FENETRE_EXTERIEURE),
        // the surface in wrong for IFC from REVIT. So we need to recalculate it, using 'cotes' height and width.
        // For Archicad, it should come from the base quantities.
        if (cotes[CotesEnum.Largeur] && cotes[CotesEnum.Hauteur]) {
          surface = coteToNumber(cotes[CotesEnum.Largeur]) * coteToNumber(cotes[CotesEnum.Hauteur])
        } else if (cotes[CotesEnum.LargeurReelle] && cotes[CotesEnum.HauteurReelle]) {
          surface = coteToNumber(cotes[CotesEnum.LargeurReelle]) * coteToNumber(cotes[CotesEnum.HauteurReelle])
        } else if (cotes[CotesEnum.LargeurUtile] && cotes[CotesEnum.HauteurUtile]) {
          surface = coteToNumber(cotes[CotesEnum.LargeurUtile]) * coteToNumber(cotes[CotesEnum.HauteurUtile])
        } else if (cotes[CotesEnum.Surface] && cotes[CotesEnum.Longueur]) {
          surface = coteToNumber(cotes[CotesEnum.Surface]) / coteToNumber(cotes[CotesEnum.Longueur])
        } else if (cotes[CotesEnum.Surface]) {
          // We had a model where only surface was filled (no longueur or largeur) and was correct
          // But we had a model where surface was false and needed to be divided by longueur...
          surface = coteToNumber((cotes[CotesEnum.Surface]))
        }
      } else {
        if(psetAcv.codeAcv === 'AME_INT_MEN_FEN' && psetAcv.occurrence === "2") {
          console.log("cotes[CotesEnum.Surface] : ", cotes[CotesEnum.Surface])
          console.log("coteToNumber(cotes[CotesEnum.Surface]) : ", coteToNumber(cotes[CotesEnum.Surface]))
        }
        surface = coteToNumber(cotes[CotesEnum.Surface])
      }

      if(psetAcv.codeAcv === 'AME_INT_MEN_FEN' && psetAcv.occurrence === "2") {
        console.log("surface : ", surface)
      }

      if (numberOfItemWithTag && numberOfItemWithTag > 1) {
        psetAcv.quantity.surface = surface / numberOfItemWithTag
      } else {
        psetAcv.quantity.surface = surface
      }
    }
  }

  /**
   * Fill the pset acv with the values from the TTB_Pset_ACV
   * The field names are changed to lower case in "memorizePsetValues", so the whole algorithm is case incensitive
   *
   * @param psetAcv : PsetAcv Final pset ACV
   * @param rawPsetAcv : any extracted, with fields lower case
   */
  function fillPsetAcv(psetAcv: PsetAcv, rawPsetAcv: any): void {
    if (rawPsetAcv[psetPropertyFileEnum.DESCRIPTION]) {
      // Get the description assigned on the pset TTP_PSET_ACV
      psetAcv.description = IfcPropertyHelper.decodeIfcProperty(rawPsetAcv[psetPropertyFileEnum.DESCRIPTION])
    }
    if (rawPsetAcv[psetPropertyFileEnum.CODE_ACV]) {
      // Get the code assigned on the pset TTP_PSET_ACV
      let codeAcvOccurence: string | undefined = rawPsetAcv[psetPropertyFileEnum.CODE_ACV]
      if (!codeAcvOccurence) {
        return
      }
      codeAcvOccurence = codeAcvOccurence.trim()
      const codeAcv: string | undefined = codeAcvOccurence.replace(numberRegex, '')
      const occurrence: string | undefined = codeAcvOccurence.replace(codeAcv, '')
      if (codeAcv) {
        psetAcv.codeAcv = codeAcv.toUpperCase()
        psetAcv.occurrence = occurrence
      }
    }
  }

  async function memorizePsetValues(): Promise<void> {
    return ifcManager.getAllItemsOfType(modelId, WebIFC.IFCPROPERTYSET, false).then((allPsetsIds) => {
      const promiseList1: Promise<undefined>[] = []
      allPsetsIds.forEach((psetId) => {
        const promise1: Promise<undefined> = ifcManager.getItemProperties(modelId, psetId, false).then((pset) => {
          const promiseList2: Promise<void>[] = []
          if (pset.Name && pset.Name.value && equalIgnoreCase(pset.Name.value.trim(), 'TTB_Pset_ACV')) {
            const promises = savePsetValues(psetId, pset, psetAcvExtracted)
            promiseList2.push(...promises)
          } else if (pset.Name && pset.Name.value && equalIgnoreCase(pset.Name.value.trim(), 'Cotes')) {
            const promises = savePsetValues(psetId, pset, coteExtracted)
            promiseList2.push(...promises)
          }
          return Promise.all(promiseList2).then(() => undefined)
        })
        promiseList1.push(promise1)
      })
      return Promise.all(promiseList1).then(() => undefined)
    })
  }

  function savePsetValues(psetId: any, pset: any, extracted: KeyValue): Promise<void>[] {
    const promiseList: Promise<void>[] = []
    const properties = pset.HasProperties
    for (const shallowProperty of properties) {
      const promise = ifcManager.getItemProperties(modelId, shallowProperty.value, false).then((psetProperty) => {
        if (psetProperty.Name && psetProperty.Name.value && psetProperty.NominalValue) {
          if (extracted[psetId] === undefined) {
            extracted[psetId] = {}
          }
          const value =
            psetProperty.NominalValue.value instanceof string
              ? psetProperty.NominalValue.value.trim().toUpperCase()
              : psetProperty.NominalValue.value
          const fieldName = psetProperty.Name.value.trim().toLowerCase()
          extracted[psetId][fieldName] = value
        }
      })
      promiseList.push(promise)
    }
    return promiseList
  }

  /**
   * Add the element id to the type id in the map
   * @param typeId the ifc type id
   * @param elementIds the ifc element ids
   * @returns void
   */
  function addElementIdsToType(typeId: number, elementIds: number[]): void {
    if (parsedValues.typesToElementIdsMap === undefined) {
      return
    }
    if (parsedValues.typesToElementIdsMap.has(typeId)) {
      const currentElementIds = parsedValues.typesToElementIdsMap.get(typeId)
      if (currentElementIds) {
        parsedValues.typesToElementIdsMap.set(typeId, [...currentElementIds, ...elementIds])
      }
    } else {
      parsedValues.typesToElementIdsMap.set(typeId, elementIds)
    }
  }

  function memorizeItemsId(ifcElementType: number): Promise<void[]> {
    return ifcManager.getAllItemsOfType(modelId, ifcElementType, false).then((itemsIds) => {
      // save all element ids for this type in the map
      addElementIdsToType(ifcElementType, itemsIds)

      const promises: Promise<void>[] = []
      itemsIds.forEach((itemId: any) => {
        itemIdsExtracted.push(itemId)
        promises.push(memorizeTags(itemId))
      })

      return Promise.all(promises)
    })
  }

  /** Memorize the tag of the element and count the number of elements with the same tag
   *
   * @param itemId
   */
  async function memorizeTags(itemId: any): Promise<void> {
    return ifcManager.getItemProperties(modelId, itemId, false).then((item) => {
      if (item.Tag?.value) {
        tagToNumber.set(item.Tag.value, (tagToNumber.get(item.Tag.value) || 0) + 1)
        itemIdToTag.set(itemId, item.Tag.value)
      }
      if (item.Tag?.type !== 1) {
        console.info('Tag with type !== 1', item.Tag)
      }
    })
  }

  async function memorizeRelDefined(): Promise<void> {
    return ifcManager.getAllItemsOfType(modelId, WebIFC.IFCRELDEFINESBYPROPERTIES, false).then((relationsIds) => {
      const promiseList: Promise<void>[] = []
      relationsIds.forEach((relationId) => {
        const promise = ifcManager.getItemProperties(modelId, relationId, false).then((relation) => {
          if (relation?.RelatedObjects?.length > 0) {
            const psetId: string = relation?.RelatingPropertyDefinition?.value
            relation.RelatedObjects.forEach((relatedObject: any) => {
              const itemId: string = relatedObject.value
              if (itemId && psetId) {
                if (relExtracted[itemId] === undefined) {
                  relExtracted[itemId] = []
                }
                relExtracted[itemId].push(psetId)
              }
            })
          }
        })
        promiseList.push(promise)
      })
      return Promise.all(promiseList).then(() => undefined)
    })
  }

  /**
   * For IFCSTAIR, IFCRAMP and IFCCURTAINWALL, thes items are composed of multiple item. One of them, the main one, contains the psetACV.
   * The other, will be the one displayed in the viewer.
   * For example, for stairs, the element with the psetACV values is IFCSTAIR, but the element whose id is needed to display it in the viewer is IFCSTAIRFLIGHT.
   */
  async function memorizeRelAggregates(): Promise<void> {
    return ifcManager.getAllItemsOfType(modelId, WebIFC.IFCRELAGGREGATES, false).then((relAggregatesIds) => {
      const promiseList: Promise<void>[] = []
      relAggregatesIds.forEach((relAggregateId) => {
        const promise = ifcManager.getItemProperties(modelId, relAggregateId, false).then((aggregates) => {
          if (aggregates?.RelatingObject?.value) {
            const ids: number[] = aggregates.RelatedObjects.map((relatedObject: any) => relatedObject.value)
            aggregatedItemsExtracted.set(aggregates.RelatingObject.value, ids)
          }
        })
        promiseList.push(promise)
      })
      return Promise.all(promiseList).then(() => undefined)
    })
  }

  async function memorizeTypes(ifcType: number): Promise<undefined> {
    return ifcManager.getAllItemsOfType(modelId, ifcType, false).then((typesIds) => {
      const promiseList: Promise<void>[] = []
      typesIds.forEach((typeId) => {
        const promise = ifcManager.getItemProperties(modelId, typeId, false).then((type) => {
          typesExtracted[typeId] = type
        })
        promiseList.push(promise)
      })
      return Promise.all(promiseList).then(() => undefined)
    })
  }

  async function memorizeTypesRelation(): Promise<undefined> {
    return ifcManager.getAllItemsOfType(modelId, WebIFC.IFCRELDEFINESBYTYPE, false).then((typeRelationsIds) => {
      const promiseList: Promise<void>[] = []
      typeRelationsIds.forEach((relationId) => {
        const promise = ifcManager.getItemProperties(modelId, relationId, false).then((relation) => {
          if (relation.RelatedObjects && relation.RelatedObjects.length > 0) {
            relation.RelatedObjects.forEach((relatedObject: any) => {
              if (typeRelationsExtracted[relatedObject.value] === undefined) {
                typeRelationsExtracted[relatedObject.value] = []
              }
              typeRelationsExtracted[relatedObject.value].push(relation.RelatingType.value)
            })
          }
        })
        promiseList.push(promise)
      })
      return Promise.all(promiseList).then(() => undefined)
    })
  }

  async function memorizeBaseQuantities(): Promise<undefined> {
    return ifcManager.getAllItemsOfType(modelId, WebIFC.IFCELEMENTQUANTITY, false).then((baseQuantityIds) => {
      const promiseList: Promise<void>[] = []
      baseQuantityIds.forEach((baseQuantityId) => {
        const promise = ifcManager.getItemProperties(modelId, baseQuantityId, false).then((baseQuantity) => {
          const quantityPromiseList: Promise<void>[] = []
          if (baseQuantity && baseQuantity.Quantities) {
            baseQuantity.Quantities.forEach((shallowQuantities: any) => {
              const quantityPromise: Promise<void> = ifcManager
                .getItemProperties(modelId, shallowQuantities.value, false)
                .then((quantity: any) => {
                  if (baseQuantitiesExtracted[baseQuantityId] === undefined) {
                    baseQuantitiesExtracted[baseQuantityId] = {}
                  }
                  baseQuantitiesExtracted[baseQuantityId][quantity.Name.value] = quantity
                })
              quantityPromiseList.push(quantityPromise)
            })
          }
          return Promise.all(quantityPromiseList).then(() => undefined)
        })
        promiseList.push(promise)
      })
      return Promise.all(promiseList).then(() => undefined)
    })
  }

  function getIfcTypePsetAcvInfo(itemId: string, psetAcv: PsetAcv): void {
    // Get psetAcv info for ifc type
    if (typeRelationsExtracted[itemId] !== undefined) {
      typeRelationsExtracted[itemId].forEach((typeId) => {
        if (typesExtracted[typeId] && typesExtracted[typeId].HasPropertySets) {
          typesExtracted[typeId].HasPropertySets.forEach((pset: any) => {
            const psetId = pset.value
            if (psetAcvExtracted[psetId] !== undefined) {
              fillPsetAcv(psetAcv, psetAcvExtracted[psetId])
            }
            if (coteExtracted[psetId] !== undefined) {
              // Save cotes from the type
              fillCotesValue(psetId, itemId, CotesEnum.Surface)

              fillCotesValue(psetId, itemId, CotesEnum.Longueur)
              fillCotesValue(psetId, itemId, CotesEnum.Largeur)
              fillCotesValue(psetId, itemId, CotesEnum.Hauteur)

              fillCotesValue(psetId, itemId, CotesEnum.LongueurUtile)
              fillCotesValue(psetId, itemId, CotesEnum.LargeurUtile)
              fillCotesValue(psetId, itemId, CotesEnum.HauteurUtile)

              fillCotesValue(psetId, itemId, CotesEnum.LongueurReelle)
              fillCotesValue(psetId, itemId, CotesEnum.LargeurReelle)
              fillCotesValue(psetId, itemId, CotesEnum.HauteurReelle)

              fillCotesValue(psetId, itemId, CotesEnum.HauteurMaximum)
            }
          })
        }
      })
    }
  }

  function fillCotesValue(psetId: string, itemId: string, coteEnum: CotesEnum): void {
    if (!coteExtractedPerItemId[itemId]) {
      coteExtractedPerItemId[itemId] = {}
    }
    if (coteExtracted[psetId][coteEnum]) {
      coteExtractedPerItemId[itemId][coteEnum] = coteExtracted[psetId][coteEnum]
    }
  }

  function getIfcItemsPsetAcvInfo(itemId: string, psetAcv: PsetAcv): void {
    // Get psetAcv info for ifc items
    const relationIdList: string[] = relExtracted[itemId]
    relationIdList?.forEach((relationId) => {
      if (psetAcvExtracted[relationId]) {
        fillPsetAcv(psetAcv, psetAcvExtracted[relationId])
      } else if (baseQuantitiesExtracted[relationId] !== undefined) {
        fillQuantityFromBaseQuantities(psetAcv, baseQuantitiesExtracted[relationId], itemId)
      } else if (coteExtracted[relationId] !== undefined) {
        const cote = { ...coteExtractedPerItemId[itemId], ...coteExtracted[relationId] }
        fillQuantityFromCotes(psetAcv, cote, itemId)
      }
    })
  }

  const ifcElements: number[] = [
    WebIFC.IFCBEAM,
    WebIFC.IFCBUILDINGELEMENT,
    WebIFC.IFCBUILDINGELEMENTPROXY,
    WebIFC.IFCCOLUMN,
    WebIFC.IFCCOVERING,
    WebIFC.IFCCURTAINWALL,
    WebIFC.IFCDOOR,
    WebIFC.IFCFLOWTERMINAL,
    WebIFC.IFCFOOTING,
    WebIFC.IFCFURNISHINGELEMENT,
    WebIFC.IFCFURNITURE,
    WebIFC.IFCMEMBER,
    WebIFC.IFCRAILING,
    WebIFC.IFCRAMP,
    WebIFC.IFCROOF,
    WebIFC.IFCSLAB,
    WebIFC.IFCSTAIR,
    WebIFC.IFCSYSTEMFURNITUREELEMENT,
    WebIFC.IFCWALL,
    WebIFC.IFCWALLSTANDARDCASE,
    WebIFC.IFCWINDOW,
    WebIFC.IFCPLATE,
  ]

  const ifcType: number[] = [
    WebIFC.IFCBEAMTYPE,
    WebIFC.IFCBUILDINGELEMENT,
    WebIFC.IFCBUILDINGELEMENTPROXYTYPE,
    WebIFC.IFCCOLUMNTYPE,
    WebIFC.IFCCOVERINGTYPE,
    WebIFC.IFCCURTAINWALLTYPE,
    WebIFC.IFCDOORSTYLE,
    WebIFC.IFCFURNISHINGELEMENTTYPE,
    WebIFC.IFCFURNITURETYPE,
    WebIFC.IFCFLOWTERMINAL,
    WebIFC.IFCFLOWTERMINALTYPE,
    WebIFC.IFCFOOTINGTYPE,
    WebIFC.IFCMEMBERTYPE,
    WebIFC.IFCPLATETYPE,
    WebIFC.IFCRAILINGTYPE,
    WebIFC.IFCRAMPFLIGHTTYPE,
    WebIFC.IFCSLABTYPE,
    WebIFC.IFCSTAIRFLIGHTTYPE,
    WebIFC.IFCWALLTYPE,
    WebIFC.IFCWINDOWSTYLE,
    WebIFC.IFCSYSTEMFURNITUREELEMENT,
  ]

  const promiseTypesList: Promise<void>[] = []
  ifcType.forEach((type) => {
    const promise = memorizeTypes(type)
    promiseTypesList.push(promise)
  })

  const promisesItemsList: Promise<void[]>[] = []
  ifcElements.forEach((type) => {
    const promise = memorizeItemsId(type)
    promisesItemsList.push(promise)
  })

  const promiseTypeRelation = memorizeTypesRelation()
  const promiseRelDefined = memorizeRelDefined()
  const promiseAggregates = memorizeRelAggregates()
  const promisePsetValues = memorizePsetValues()
  const promiseBaseQuantities = memorizeBaseQuantities()

  const psetAcvCodeToPsetAcv: CodeToPsetAcv = {}
  // key: code+occurence like "ENV_TOI_PEN1", values are all the ids from aggregated item. It will avoid counting an item twice
  const aggregatedPsetItemIds: Map<string, number[]> = new Map<string, number[]>()

  // Test if the item was already added. In which case, nothing to do. It should not be added again
  function alreadyAdded(key: string, newId: number): boolean {
    const itemIds: number[] | undefined = aggregatedPsetItemIds.get(key)
    if (itemIds === undefined) {
      return false
    } else {
      return itemIds.find((itemId) => itemId === newId) !== undefined
    }
  }

  function addToKeyValues(psetAcv: PsetAcv): void {
    const key: string = psetAcv.codeAcv + psetAcv.occurrence

    if (psetAcvCodeToPsetAcv[key] === undefined) {
      psetAcvCodeToPsetAcv[key] = []
    }
    // @ts-ignore: it can't be undefined. The previous lines just took care of it
    psetAcvCodeToPsetAcv[key].push(psetAcv)
  }

  function addToMap(map: Map<string, number[]>, key: string, value: number): void {
    if (map.get(key) === undefined) {
      map.set(key, [])
    }
    map.get(key)?.push(value)
  }

  async function getParsedValues(): Promise<ParsedValues> {
    aggregatedItemsExtracted.forEach((childrenItemIds, itemId) => {
      const psetAcv: PsetAcv | undefined = new PsetAcv('NO_CODE')
      getIfcTypePsetAcvInfo(itemId, psetAcv)
      getIfcItemsPsetAcvInfo(itemId, psetAcv)
      if (psetAcv.codeAcv !== 'NO_CODE' && childrenItemIds) {
        const key: string = psetAcv.codeAcv + psetAcv.occurrence
        const itemIdNumber = parseInt(itemId, 10)
        psetAcv.elementIds.push(itemIdNumber)
        addToMap(aggregatedPsetItemIds, key, itemIdNumber)
        // Get ids of visible elements for the viewer
        childrenItemIds.forEach((ifcObjectsId) => {
          if (psetAcv.elementIds) {
            psetAcv.elementIds.push(ifcObjectsId)
            addToMap(aggregatedPsetItemIds, key, ifcObjectsId)
          }
        })
        addToKeyValues(psetAcv)
      }
    })

    // Normal items
    itemIdsExtracted.forEach((itemId) => {
      const psetAcv: PsetAcv | undefined = new PsetAcv('NO_CODE')
      getIfcTypePsetAcvInfo(itemId, psetAcv)
      getIfcItemsPsetAcvInfo(itemId, psetAcv)
      if (psetAcv.codeAcv !== 'NO_CODE') {
        const key = psetAcv.codeAcv + psetAcv.occurrence
        const itemIdNumber = parseInt(itemId, 10)
        if (!alreadyAdded(key, itemIdNumber)) {
          psetAcv.elementIds = [parseInt(itemId, 10)]
          addToKeyValues(psetAcv)
        }
      }
    })

    console.info('psetAcvCodeToPsetAcv: ', psetAcvCodeToPsetAcv)
    // @ts-ignore : values inside the array cannot be undefined because we filtered out undefined
    const values: PsetAcv[][] = Object.values(psetAcvCodeToPsetAcv).filter((x) => x !== undefined)
    const psetAcvList: PsetAcv[] = values !== undefined ? values.flatMap((x: PsetAcv[]) => x) : []

    console.info('pset ACV list: ', psetAcvList.length)

    const end = Date.now()
    console.info(`Time to extract ACV info: ${(end - start) / 1000} s`)
    parsedValues.psetAcvs = psetAcvList.filter((psetAcv) => psetAcv.codeAcv !== 'NO_CODE')
    return parsedValues
  }

  return Promise.all([
    ...promiseTypesList,
    ...promisesItemsList,
    promiseTypeRelation,
    promiseRelDefined,
    promisePsetValues,
    promiseBaseQuantities,
    promiseAggregates,
  ]).then(() => {
    setProgress(75)
    return getParsedValues()
  })
}

// function typeToString(type: number): string {
//   switch (type) {
//     case WebIFC.IFCCOVERING:
//       return 'IFCCOVERING'
//     case WebIFC.IFCCURTAINWALL:
//       return 'IFCCURTAINWALL'
//     case WebIFC.IFCWINDOW:
//       return 'IFCWINDOW'
//     case WebIFC.IFCDOOR:
//       return 'IFCDOOR'
//     case WebIFC.IFCBUILDINGELEMENTPROXY:
//       return 'IFCBUILDINGELEMENTPROXY'
//     case WebIFC.IFCRAILING:
//       return 'IFCRAILING'
//     case WebIFC.IFCWALL:
//       return 'IFCWALL'
//     case WebIFC.IFCWALLSTANDARDCASE:
//       return 'IFCWALLSTANDARDCASE'
//     case WebIFC.IFCSLAB:
//       return 'IFCSLAB'
//     case WebIFC.IFCBEAM:
//       return 'IFCBEAM'
//     case WebIFC.IFCCOLUMN:
//       return 'IFCCOLUMN'
//     case WebIFC.IFCSTAIR:
//       return 'IFCSTAIR'
//     case WebIFC.IFCFOOTING:
//       return 'IFCFOOTING'
//     case WebIFC.IFCBEAMTYPE:
//       return 'IFCBEAMTYPE'
//     case WebIFC.IFCBUILDINGELEMENTTYPE:
//       return 'IFCBUILDINGELEMENTTYPE'
//     case WebIFC.IFCCOLUMNTYPE:
//       return 'IFCCOLUMNTYPE'
//     case WebIFC.IFCCOVERINGTYPE:
//       return 'IFCCOVERINGTYPE'
//     case WebIFC.IFCCURTAINWALLTYPE:
//       return 'IFCCURTAINWALLTYPE'
//     case WebIFC.IFCMEMBERTYPE:
//       return 'IFCMEMBERTYPE'
//     case WebIFC.IFCPLATETYPE:
//       return 'IFCPLATETYPE'
//     case WebIFC.IFCRAILINGTYPE:
//       return 'IFCRAILINGTYPE'
//     case WebIFC.IFCRAMPFLIGHTTYPE:
//       return 'IFCRAMPFLIGHTTYPE'
//     case WebIFC.IFCSLABTYPE:
//       return 'IFCSLABTYPE'
//     case WebIFC.IFCSTAIRFLIGHTTYPE:
//       return 'IFCSTAIRFLIGHTTYPE'
//     case WebIFC.IFCWALLTYPE:
//       return 'IFCWALLTYPE'
//     default:
//       return 'UNKNOWN'
//   }
// }
