import { ListItemIcon, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

type IProps = {
  action(): void
  Icone: React.ReactElement
  label: string
  link?: string
}
export function SettingsMenuItem({ action, Icone, label, link }: IProps): React.JSX.Element {
  const [hover, setHover] = useState(false)

  const clonedIcon = React.cloneElement(Icone, {
    fontSize: 'medium',
    sx: { ...Icone.props.sx, color: hover ? 'black' : 'white' },
  })

  return (
    <MenuItem
      disableGutters
      component={Link}
      to={link || ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={action}>
      <ListItemIcon>{clonedIcon}</ListItemIcon>
      {label}
    </MenuItem>
  )
}
