import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

type IData = {
  actorType: 'PERSON' | 'COMPANY' | string
  personName?: string
  personFirstName?: string
  specialty?: string
  companyName?: string
}

type ITermOfServiceDialogProps = {
  handleConfirm: () => void
  openDialog: boolean
  setOpenDialog: (v: boolean) => void
  loading?: boolean
  data?: IData
}

export default function TermOfServiceDialog({
  handleConfirm,
  openDialog,
  setOpenDialog,
  loading,
  data,
}: Readonly<ITermOfServiceDialogProps>): React.JSX.Element {
  function handleClose(): void {
    setOpenDialog(false)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth='lg'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>
        <Typography variant='h3'>Condition d'utilisation</Typography>
      </DialogTitle>
      <DialogContent id='alert-dialog-description' dividers>
        <Typography variant='h4'>Préambule</Typography>
        <ol>
          <li>
            La société BE VIVIEN est un bureau d’étude spécialisé dans les projets de constructions neufs et de rénovation
            notamment sur les questions de carbones et de fluides.
          </li>

          {data && data.actorType === 'PERSON' && (
            <li>
              {data.personName} {data.personFirstName} est une personne physique spécialisée dans le domaine suivant: "
              {data.specialty}"
            </li>
          )}
          {data && data.actorType === 'COMPANY' && (
            <li>
              {data.companyName} est une scociété spécialisée dans le domaine suivant: "{data.specialty}"
            </li>
          )}

          <li>
            Les Parties conviennent de s’échanger plusieurs informations, notamment de nature stratégique, commercial,
            technique, financière, organisationnelle, en vue de la réalisation du projet d’un logiciel de calcul permettant
            d’améliorer l’indice carbone dans les immeubles. Ce logiciel est accompagné d’une notice d’utilisation détaillant
            les méthodes et principes de calculs associés à ce logiciel (ci-après le « Projet »).
          </li>
          <li>
            Compte-tenu des intérêts en jeu, du caractère stratégique des informations de toutes natures échangées entre
            Parties, celles-ci souhaitent pouvoir encadrer la confidentialité des échanges au cours du Projet.
          </li>
          <li>
            Le présent Accord fixe les conditions applicables à la communication par les Parties d’Informations
            Confidentielles dans le cadre du Projet (ci-après l’« Accord »).
          </li>
          <li>
            Dans le cadre du Projet, chacune des Parties (désignée en cette qualité la « Partie Emettrice ») communiquera à
            l’autre Partie (désignée en cette qualité la « Partie Destinataire ») certaines informations à caractère
            confidentiel.
          </li>
        </ol>
        <Typography variant='h4' gutterBottom>
          Il a été convenu ce qui suit:
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 1: Définitions
        </Typography>
        <Typography gutterBottom>
          On désigne, par « Informations Confidentielles », sans que cela soit limitatif, toutes informations et données de
          toute nature divulguées par l'une ou l'autre des Parties, orales et écrites, notamment techniques, scientifiques,
          économiques, financières, commerciales, comptables, toutes méthodologies ou information s’y rapportant, tous plans,
          études, prototypes, matériels, audits, données expérimentales et de test, dessins, représentations graphiques,
          spécifications, savoir-faire, expériences, logiciels et programmes, y compris le code source d’un logiciel non
          diffusé sous licence à code ouvert et les demandes de brevets en cours de préparation, quelle qu’en soit la forme,
          le support ou le moyen, incluant, sans limitation, les communications orales, écrites ou fixées sur un support
          quelconque, que les Parties sont amenées à se communiquer dans le cadre des discussions sur le Projet.
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 2: Objet de l'accord
        </Typography>
        <Typography gutterBottom>
          Le présent Accord a pour objet de définir l’obligation de confidentialité qui s’impose aux Parties dans le cadre
          des échanges du Projet.
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 3: Obligation de confidentialité
        </Typography>
        <Typography>
          Chacune des Parties s’engage à garder secrète et confidentielle toute Information Confidentielle transmise par
          l’autre Partie et à prendre toutes mesures nécessaires pour en préserver la confidentialité à l’égard de leurs
          personnels ou de toutes autres personnes se trouvant sous sa responsabilité, ainsi que des tiers impliqués dans la
          mise en place du Projet, le cas échéant.
        </Typography>
        <Typography>A cette fin, les Parties s’engagent à :</Typography>
        <ul>
          <li>
            ce que les Informations Confidentielles reçues soient traitées avec le même degré de précaution et de protection
            que celui accordé à leurs propres Informations Confidentielles
          </li>
          <li>
            ce que les Informations Confidentielles échangées ne soient utilisées que pour la mise en place du Projet, sauf
            accord préalable écrit de la Partie Emettrice de l’Information Confidentielle
          </li>
          <li>
            ne pas effectuer de copies de tout ou Partie des Informations Confidentielles, sauf accord écrit préalable de la
            Partie Emettrice
          </li>
          <li>
            ne révéler les Informations Confidentielles qu’aux membres de leurs personnels impliqués dans la mise en place du
            Projet, et qui auront besoin de prendre connaissance de ces Informations Confidentielles
          </li>
          <li>
            ne pas communiquer d’Informations Confidentielles de l’autre Partie à des tiers, sauf accord écrit et préalable
            de la Partie Emettrice
          </li>
          <li>
            le cas échéant, à prendre toutes les dispositions nécessaires pour que tous les membres de leurs personnels et
            tous les tiers impliqués dans la mise en place du Projet, qui auront communication d’Informations
            Confidentielles, s’engagent, avant toute communication, à traiter les informations avec le même degré de
            confidentialité que celui résultant du présent Accord
          </li>
          <li>
            à n’utiliser les Informations Confidentielles de l’autre Partie qu’en rapport avec le Projet étant précisé qu’une
            information ne sera pas considérée comme confidentielle si la Partie qui l’a reçue peut prouver :
          </li>
          <ul>
            <li>
              que cette Information Confidentielle appartenait au domaine public au moment de sa divulgation ou après
              celle-ci mais dans ce cas en l'absence de toute faute qui lui soit imputable
            </li>
            <li>
              qu’elle détenait d’ores et déjà cette Information Confidentielle, légalement et sans faute ou fraude de sa
              part, avant que la Partie émettrice ne le lui transmette
            </li>
            <li>qu’elle l’a régulièrement reçue d’un tiers libre d’en disposer</li>
            <li>qu'elle a été publiée sans contrevenir aux dispositions du présent Accord</li>
            <li>qu'elle a été désignée ou confirmée comme Informations Confidentielles ; ou</li>
            <li>
              qu’elle a été légalement obligée de communiquer cette Information Confidentielle dans le cadre d’une procédure
              judiciaire ou toute autre mesure légale, à condition que l’autre Partie ait été prévenues dans un laps de temps
              raisonnable, de la divulgation de l’Information Confidentielle et du contenu de l’Information Confidentielle.
            </li>
          </ul>
        </ul>
        <Typography variant='h5' gutterBottom>
          Article 4: Propriété des informations confidentielles
        </Typography>
        <Typography>
          Toutes les Informations Confidentielles et les supports communiqués, et remis par chaque Partie qui en est
          propriétaire, sont et resteront la propriété exclusive de celle-ci.
        </Typography>
        <Typography>
          Le présent Accord n’entraîne en aucun cas la cession ou la concession de droits d’exploitation sur les dossiers de
          savoir-faire, les brevets, les logiciels et tout autre droit de propriété intellectuelle détenu par une Partie à
          l’autre Partie.
        </Typography>
        <Typography gutterBottom>
          La Partie Destinataire de l’Information Confidentielle s'interdit de revendiquer la propriété, corporelle ou
          incorporelle, des Informations Confidentielles, d'effectuer tout dépôt ou formalité de quelque nature que ce soit
          et, plus généralement, de mettre en œuvre une quelconque action visant à faire reconnaître à son profit ou au
          profit d'un tiers un droit de propriété intellectuelle lié aux Informations Confidentielles.
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 5: Garanties
        </Typography>
        <Typography gutterBottom>
          L’Information Confidentielle est fournie telle quelle et peut être modifiée à tout moment par la Partie Emettrice.
          La Partie Destinataire reconnaît que les Informations Confidentielles sont fournies « en l’état ». La Partie
          Émettrice ne pourra en aucun cas être tenue responsable vis-à-vis de la Partie Destinataire pour des dommages
          directs, indirects, spéciaux ou consécutifs concernant ou découlant de l’utilisation de tout ou partie des
          Informations Confidentielles, et notamment pour une quelconque garantie en ce qui concerne l’exhaustivité,
          l’exactitude, la sécurité ou l’adaptation à une utilisation particulière des Informations Confidentielles.
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 6: Restitution
        </Typography>
        <Typography>
          En cas de renonciation d’une Partie au Projet ou d’abandon du Projet par toutes les Parties, les Parties s’engagent
          à restituer spontanément, sans délai, l’ensemble des Informations Confidentielles qui leur ont été transmises, sans
          qu’il en soit conservé de copie, que ce soit sur support papier ou support dématérialisé.
        </Typography>
        <Typography gutterBottom>
          La Partie Destinataire s’engage à justifier sans délai de telles restitutions à la Partie Emettrice qui en ferait
          la demande.
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 7: Durée - Entrée en vigueur
        </Typography>
        <Typography>
          Le présent Accord entre en vigueur à la date de signature entre les Parties, pour une période de cinq (5) ans.
        </Typography>
        <Typography gutterBottom>
          Les Parties peuvent, à la fin ou au cours des discussions, conclure un accord organisant leurs relations
          contractuelles ou décider d'interrompre toutes les discussions et tous les échanges sur le projet. La clôture des
          discussions n'entraîne pas la perte du caractère confidentiel des informations transmises pendant la période prévue
          ci-dessus.
        </Typography>
        <Typography variant='h5' gutterBottom>
          Article 8: Divers
        </Typography>
        <Typography variant='h6' gutterBottom>
          Intégralité de l’Accord
        </Typography>
        <Typography gutterBottom>Le présent Accord exprime l’intégralité de l’accord des Parties sur son objet.</Typography>
        <Typography variant='h6'>Pas de représentation</Typography>
        <Typography gutterBottom>
          Chaque Partie agit en son nom propre et sous sa seule responsabilité et s’interdit de prendre un engagement au nom
          et pour le compte de l’autre.
        </Typography>
        <Typography variant='h6'>Incessibilité</Typography>
        <Typography gutterBottom>
          Le présent Accord a été conclu par chaque Partie en considération de la personne de l’autre Partie et ne pourra en
          aucun cas faire l’objet d’une cession, totale ou partielle, à titre onéreux ou gratuit, par l’une des Parties sans
          l’accord écrit et préalable de l’autre.
        </Typography>
        <Typography variant='h5'>Article 9: Loi applicable et réglement des litiges</Typography>
        <Typography>Le présent Accord est soumis à la loi française.</Typography>
        <Typography gutterBottom>
          En cas de différend sur son interprétation ou son exécution, les Parties s’efforceront de le résoudre amiablement.
        </Typography>
        <Typography variant='h6'>
          A DEFAUT DE REGLEMENT AMIABLE DANS UN DELAI DE DEUX (2) MOIS A COMPTER DE LA NOTIFICATION DE LA CONTESTATION PAR
          L’UNE DES PARTIES AU MOYEN D’UNE LETTRE RECOMMANDEE AVEC ACCUSE DE RECEPTION, LA PARTIE LA PLUS DILIGENTE PORTERA
          LE LITIGE DEVANT LE TRIBUNAL COMPETENT DANS LE RESSORT DE LA COUR D’APPEL DE PARIS.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Refuser</Button>
        <LoadingButton
          loading={loading}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleConfirm()
          }}>
          Accepter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
