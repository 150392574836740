import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorContext } from '../../../../components/layout/error-snackbar'
import { SuccessContext } from '../../../../components/layout/success-snackbar'
import { Children } from '../../../../components/miscellianous/children'
import { pagesUrl } from '../../../appConstants'
import { Organization } from '../../../dto/organization/organization'
import { RseeProject } from '../../../dto/rsee/rsee-project'
import { RseeProjectCreation } from '../../../dto/rsee/rsee-project-creation'
import { RseeProjectForm } from '../../../dto/rsee/rsee-project-form'
import { ProjectTypeEnum } from '../../../enum/project/projectTypeEnum'
import { ProjectStatusEnum } from '../../../enum/projectStatusEnum'
import { useRsee } from '../../../hooks/rsee/use-rsee'
import { resolveUrl } from '../../../services/http-service'
import { OrganizationContext } from '../../organization/organization-context'

export const RseeProjectContext = React.createContext<RseeProjectHook>({} as RseeProjectHook)

export interface IForm {
  id: string | undefined
  projectName: string
  businessCode: string
  projectStatus: ProjectStatusEnum
}

export function formToDto(form: IForm): RseeProjectCreation {
  return {
    projectName: form.projectName,
    businessCode: form.businessCode,
  }
}

export function updateFormToDto(form: IForm): RseeProjectForm {
  return {
    projectName: form.projectName,
    businessCode: form.businessCode,
    status: form.projectStatus,
  }
}

export function dtoToForm(rseeProject: RseeProject | undefined): IForm {
  return rseeProject
    ? {
        id: rseeProject.id,
        projectName: rseeProject.projectName,
        businessCode: rseeProject.businessCode,
        projectStatus: rseeProject.projectStatus,
      }
    : {
        id: '',
        projectName: '',
        businessCode: '',
        projectStatus: ProjectStatusEnum.IN_PROGRESS,
      }
}

export function RseeProjectContextProvider({ children }: Children): React.JSX.Element {
  const navigate = useNavigate()
  const { rseeProjectId } = useParams()
  const { updateRseeProject } = useRsee()
  const { deleteRseeProject } = useRsee()
  const { fetchRseeProject, createRseeProject, addRseeDocument, updateRseeDocument } = useRsee()

  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const { refreshOrganization } = useContext(OrganizationContext)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const [rseeProject, setRseeProject] = useState<RseeProject | undefined>()
  const [isRseeProjectLoading, setIsRseeProjectLoading] = useState<boolean>(false)

  const refreshProject = useCallback((): void => {
    if (rseeProjectId) {
      setIsRseeProjectLoading(true)
      fetchRseeProject(rseeProjectId)
        .then((newRseeProject) => {
          setRseeProject(newRseeProject)
        })
        .catch((e) => {
          if (e?.status === 404) {
            navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
          } else {
            throw e
          }
        })
        .finally(() => {
          setIsRseeProjectLoading(false)
        })
    }
  }, [fetchRseeProject, navigate, rseeProjectId])

  useEffect(() => refreshProject(), [fetchRseeProject, navigate, refreshProject, rseeProject?.id, rseeProjectId])

  const createProject = useCallback(
    (rseeProjectCreationDto: RseeProjectCreation) =>
      createRseeProject(rseeProjectCreationDto).then((newRseeProject) => {
        setRseeProject(newRseeProject)
        return newRseeProject
      }),
    [createRseeProject]
  )

  const addDocument = useCallback(
    (rseeFile: File) => {
      if (rseeProjectId) {
        return addRseeDocument(rseeFile, rseeProjectId)
      }
      openErrorSnackbar(new Error("Erreur: il n'y a pas de projet Beem Pilot sélectionné"))
      return Promise.resolve(undefined)
    },
    [addRseeDocument, openErrorSnackbar, rseeProjectId]
  )

  const updateStatusToArchived = useCallback(() => {
    if (rseeProject?.id) {
      const rseeProjectDto: RseeProjectForm = {
        status: ProjectStatusEnum.ARCHIVED,
        projectName: rseeProject.projectName,
        businessCode: rseeProject.businessCode,
      }
      return updateRseeProject(rseeProject.id, rseeProjectDto).then((updateToArchived) => {
        setRseeProject(updateToArchived)
        openSuccessSnackbar("Statut du projet mis à jour à l'état Archivé")
      })
    }
    return Promise.resolve()
  }, [openSuccessSnackbar, rseeProject?.businessCode, rseeProject?.id, rseeProject?.projectName, updateRseeProject])

  const handleDeleteRseeProject = useCallback(
    (isValid: boolean) => {
      if (rseeProject?.id && rseeProject.projectStatus !== ProjectStatusEnum.ARCHIVED) {
        return updateStatusToArchived().then(() => openSuccessSnackbar('Archivage du projet RSEE réalisé avec succès'))
      } else if (isValid && rseeProject?.id && !isDeleting) {
        setIsDeleting(true)
        return deleteRseeProject(rseeProject.id)
          .then(() => refreshOrganization())
          .then(() => openSuccessSnackbar('Suppression du projet RSEE réalisé avec succès')) // Refresh organization to refresh the projects counter
          .finally(() => {
            setIsDeleting(false)
          })
      }
      return Promise.resolve()
    },
    [
      deleteRseeProject,
      isDeleting,
      openSuccessSnackbar,
      refreshOrganization,
      rseeProject?.id,
      rseeProject?.projectStatus,
      updateStatusToArchived,
    ]
  )

  const updateProject = useCallback(
    (rseeProjectForm: RseeProjectCreation) => {
      if (rseeProject?.id) {
        return updateRseeProject(rseeProject.id, rseeProjectForm).then((newRseeProject) => {
          setRseeProject(newRseeProject)
        })
      }
      return Promise.resolve()
    },
    [rseeProject?.id, updateRseeProject]
  )

  const rseeProjectHook: RseeProjectHook = useMemo(
    () => ({
      rseeProject,
      isRseeProjectLoading,
      createProject,
      addDocument,
      handleDeleteRseeProject,
      setRseeProject,
      updateProject,
      refreshProject,
    }),
    [addDocument, createProject, handleDeleteRseeProject, isRseeProjectLoading, refreshProject, rseeProject, updateProject]
  )

  return <RseeProjectContext.Provider value={rseeProjectHook}>{children}</RseeProjectContext.Provider>
}

export type RseeProjectHook = {
  rseeProject?: RseeProject
  isRseeProjectLoading: boolean
  setRseeProject: Dispatch<SetStateAction<RseeProject | undefined>>
  createProject: (rseeProjectCreationDto: RseeProjectCreation) => Promise<RseeProject>
  addDocument(rseeFile: File): Promise<string | undefined>
  handleDeleteRseeProject(isValid: boolean): Promise<void> | Promise<Organization | undefined>
  updateProject(rseeProjectForm: RseeProjectCreation): Promise<void>
  refreshProject(): void
}
