import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { CustomBottomNavigation } from '../../../components/buttons/navigate-button/CustomBottomNavigation'
import { IconMapPin } from '../../../components/custom-icons/icon-map-pin'
import { IconTrashX } from '../../../components/custom-icons/icon-trash-x'
import { RseeProjectContext } from '../../../core/context/beem-pilot/rsee/rsee-project-context'
import { RseeTeamProjectContext } from '../../../core/context/beem-pilot/rsee/rsee-team-project-context'
import {
  organizationRoleEnumEnumLabels,
  OrganizationRoleEnumForSelect,
} from '../../../core/enum/user/organization-role-enum'
import { enumToSelectOptions, getFullNameLabel } from '../../../core/services/helper-service'
import { SearchAndFilter } from '../components/SearchAndFilter'
import { useSearchAndFilter } from '../components/useSearchAndFilter'
import { RoleUserChip } from './components/RoleUserChip'
import { RseeInviter } from './components/RseeInviter'
import RseeRevokeDialog from './components/RseeRevokeDialog'

export function RseeTeamPage(): React.JSX.Element {
  const { currentTeam, revokeUser, cognitoUserIdToRevoke, labelToRevoke, setLabelToRevoke, setCognitoUserIdToRevoke } =
    useContext(RseeTeamProjectContext)
  const { rseeProject } = useContext(RseeProjectContext)

  const [open, setOpen] = useState<boolean>(false)
  const [isRevokeDialogOpen, setIsRevokeDialogOpen] = useState(false)

  const { statusFilter, searchState, handleSelectChange, handleSearch, itemsToShow } = useSearchAndFilter(
    currentTeam,
    ['familyName', 'givenName'],
    'role'
  )

  function handleClose(): void {
    setOpen(false)
  }

  function onCloseRevoqueDialog(): void {
    setIsRevokeDialogOpen(false)
    setLabelToRevoke('')
    setCognitoUserIdToRevoke(undefined)
  }

  return (
    <Box display='flex' flexDirection='column' sx={{ pt: 2 }}>
      <SearchAndFilter
        searchState={searchState}
        handleSearch={handleSearch}
        selectedOption={statusFilter}
        options={enumToSelectOptions(OrganizationRoleEnumForSelect, organizationRoleEnumEnumLabels).filter(
          (actualRoleEnumForSelect) => actualRoleEnumForSelect.value !== OrganizationRoleEnumForSelect.TEST_USER
        )}
        handleChange={handleSelectChange}
        searchStateLabel='Rechercher par nom'
      />

      <Grid container alignItems='flex-start' height='100%' rowGap={1}>
        {itemsToShow.length > 0 ? (
          <>
            <Grid container px={2} pb={1}>
              <Grid item xs={3}>
                <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                  Nom
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                  Agence
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                  Role dans l'organisation
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {itemsToShow.map((x) => {
              const fullName = getFullNameLabel(x.givenName, x.familyName)
              return (
                <Grid
                  display='flex'
                  alignItems='center'
                  container
                  key={x.cognitoUserId}
                  borderRadius={4}
                  p={2}
                  py={3}
                  sx={{ boxShadow: '2px 4px 20px 0px #0000000D', background: 'white' }}>
                  <Grid item xs={3}>
                    {fullName}
                  </Grid>
                  <Grid item xs={5}>
                    <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                      <IconMapPin />
                      {x.organizationName}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <RoleUserChip role={x.role} />
                  </Grid>

                  {rseeProject && rseeProject?.hasRight ? (
                    <Grid item xs={1}>
                      <Tooltip title='Révoquer ses droits'>
                        <IconButton
                          onClick={() => {
                            setCognitoUserIdToRevoke(x.cognitoUserId)
                            if (x.givenName && x.familyName) {
                              setLabelToRevoke(getFullNameLabel(x.givenName, x.familyName))
                            } else {
                              setLabelToRevoke('')
                            }

                            setIsRevokeDialogOpen(true)
                            return Promise.resolve()
                          }}>
                          <IconTrashX />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : (
                    <Grid item xs={1} />
                  )}
                </Grid>
              )
            })}
          </>
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            minHeight='40vh'
            width='100%'>
            <Typography fontSize='24px' fontWeight={600} lineHeight='36px' textAlign='center' color='#374771'>
              Aucun résultat
            </Typography>
            <Typography variant='body1'>Modifier les options de recherche.</Typography>
          </Box>
        )}
      </Grid>

      <RseeInviter open={open} handleClose={handleClose} />

      {rseeProject?.hasRight && (
        <CustomBottomNavigation actionLabel='Inviter un collaborateur' actionButton={() => setOpen(true)} />
      )}

      <RseeRevokeDialog
        open={isRevokeDialogOpen}
        revokeRole={() => revokeUser().finally(() => onCloseRevoqueDialog())}
        selectedCognitoId={cognitoUserIdToRevoke}
        selectedUserName={labelToRevoke}
        handleClose={onCloseRevoqueDialog}
      />
    </Box>
  )
}
