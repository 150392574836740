import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import {Box, Button, Grid, Typography} from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import city from '../../../assets/img/city.png'
import {ProjectCardStatus} from '../../components/card/project-status-card'
import {IconBeemMaster} from '../../components/custom-icons/icon-beem-master'
import {IconBeemPilot} from '../../components/custom-icons/icon-beem-pilot'
import {IconFileAnalytics} from '../../components/custom-icons/icon-file-analytics'
import {IconHome} from '../../components/custom-icons/icon-home'
import {DefaultTypography} from '../../components/typography/default-typography'
import {pagesUrl} from '../../core/appConstants'
import {BSVariantContext} from '../../core/context/beem-shot/BSVariant/BSVariantContext'
import {AppNavBarContext} from '../../core/context/nav-bar/AppNavBarContext'
import {OrganizationContext} from '../../core/context/organization/organization-context'
import {AppSideBarContext} from '../../core/context/side-bar/side-bar-context'
import {HomePageIndicator} from '../../core/dto/home-page-indicator'
import {SimpleInformationsProject} from '../../core/dto/simple-project-information/SimpleInformationsProject'
import {SimpleInformationProjectEnum} from '../../core/enum/SimpleInformationProjectEnum'
import {useHomePage} from '../../core/hooks/use-home-page'
import {formatToFrench} from '../../core/services/date-service'
import {resolveUrl} from '../../core/services/http-service'
import {HomeCardIndicator} from './components/HomeCardIndicator'

export function HomePage(): React.JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()

  const { getHomePageIndicator, getLastProject } = useHomePage()

  const { setShowAppSide, setFullScreen } = useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setIcon } = useContext(AppNavBarContext)
  const { organization } = useContext(OrganizationContext)
  const { fetchAllBsVariants } = useContext(BSVariantContext)

  const [homePageIndicator, setHomePageIndicator] = useState<HomePageIndicator>()
  const [simpleProjectList, setSimpleProjectList] = useState<SimpleInformationsProject[]>()

  useEffect(() => {
    setShowAppSide(true)
    setTitle('Beem Shot')
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setIcon(<IconHome style={{ color: '#0BE2A0', fontWeight: '2px' }} />)
    setFullScreen(true)

    return () => {
      setFullScreen(false)
    }
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setIcon, setFullScreen])

  useEffect(() => {
    getHomePageIndicator().then((e) => setHomePageIndicator(e))
  }, [getHomePageIndicator])

  useEffect(() => {
    getLastProject(3).then((e) => setSimpleProjectList(e))
  }, [getHomePageIndicator, getLastProject])

  function getIcone(type: SimpleInformationProjectEnum | undefined): JSX.Element | undefined {
    switch (type) {
      case SimpleInformationProjectEnum.BS_PROJECT:
        return <DonutSmallOutlinedIcon fontSize='medium' sx={{ color: '#0BE2A0', fontWeight: '2px' }} />
      case SimpleInformationProjectEnum.RSEE_PROJECT:
        return <IconBeemPilot />
      case SimpleInformationProjectEnum.PROJECT:
        return <IconBeemMaster style={{ color: '#0BE2A0', fontWeight: '2px' }} />
      default:
        return undefined
    }
  }

  function goToProject(project: SimpleInformationsProject): void {
    switch (project.typeProject) {
      case SimpleInformationProjectEnum.BS_PROJECT:
        fetchAllBsVariants(project.projectId).then((variants) => {
          if (variants.length === 0) {
            return navigate(pagesUrl.BEEM_SHOT_HOME)
          } else {
            return navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECT, [project.projectId, variants[0].id]))
          }
        })
        return undefined
      case SimpleInformationProjectEnum.PROJECT:
        return navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.projectId]))
      case SimpleInformationProjectEnum.RSEE_PROJECT:
        return navigate(resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [project.projectId]))
      default:
        return undefined
    }
  }

  return (
    <Grid container spacing={1} rowGap={2} pt={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle1' fontSize='24px'>
          Bienvenue 👋
        </Typography>
      </Grid>

      {organization?.beemShotOption && (
        <Grid item xs={4}>
          <HomeCardIndicator
            icon={<DonutSmallOutlinedIcon style={{ width: 44, height: 44, color: 'black' }} />}
            number={homePageIndicator?.countBsProject ?? 0}
            moduleName='Beem Shot'
            backgroundColor='#C9FFE8'
            link={pagesUrl.BEEM_SHOT_HOME}
          />
        </Grid>
      )}

      {organization?.beemMasterOption && (
        <Grid item xs={4}>
          <HomeCardIndicator
            icon={<IconBeemMaster style={{ width: 44, height: 44, color: 'black' }} />}
            number={homePageIndicator?.countProject ?? 0}
            moduleName='Beem Master'
            backgroundColor='#F8BBD0'
            link={pagesUrl.PROJECTS_PAGE}
          />
        </Grid>
      )}

      {organization?.beemPilotOption && (
        <Grid item xs={4}>
          <HomeCardIndicator
            icon={<IconFileAnalytics style={{ width: 44, height: 44, color: 'black' }} />}
            number={homePageIndicator?.countRseeProject ?? 0}
            moduleName='Beem Pilot'
            backgroundColor='#CEDEEF'
            link={pagesUrl.BEEM_PILOT_HOME}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant='h6'>Derniers projets créés</Typography>
      </Grid>

      <Grid container display='flex' item xs={12} rowGap={1}>
        {simpleProjectList?.map((project) => (
          <Grid
            key={project.projectId}
            container
            rowGap={1}
            alignItems='center'
            borderRadius={3}
            p={1}
            onClick={() => goToProject(project)}
            justifyContent='space-between'
            sx={{
              backgroundColor: 'white',
              boxShadow: '2px 4px 20px 0px rgba(0, 0, 0, 0.05)',
              ':hover': { cursor: 'pointer', boxShadow: 10 },
            }}>
            <Grid item container display='flex' xs={4} columnGap={3} rowGap={2}>
              <Box
                display='flex'
                alignItems='center'
                sx={{ borderRadius: 2, background: '#b7ffe3', p: 1, borderColor: 'white' }}>
                {getIcone(project.typeProject)}
              </Box>

              <Box>
                <Typography variant='subtitle1'>{project.projectName}</Typography>

                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  alignContent='center'
                  columnGap={0.5}
                  justifyItems='center'>
                  <DefaultTypography label='Crée par ' color='#070F27' fontWeight={400} fontSize='11px' />
                  <DefaultTypography label={project?.createdByUser ?? ''} color='#070F27' fontWeight={500} fontSize='11px' />
                  <Typography variant='subtitle1' fontSize={11}>
                    {`· ${organization?.businessName} · `}
                  </Typography>

                  <Typography variant='subtitle1' fontSize={11} fontWeight={400} sx={{ color: '#8F8F8F' }}>
                    {`Le ${formatToFrench(project.createdDate)} `}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid container item xs={2} display='flex' justifyContent='flex-end' alignItems='center' columnGap={3}>
              <Box display='flex' alignItems='center' sx={{ background: '#000', px: 1, borderRadius: 2 }}>
                <PersonOutlinedIcon fontSize='small' sx={{ color: '#fff' }} />
                <DefaultTypography label='1' fontSize='14px' lineHeight='20.02px' fontWeight={400} color='#ffffff' />
              </Box>

              <ProjectCardStatus status={project.status} />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={1}>
        <Grid container item xs={8}>
          <Box
            display='flex'
            flexDirection='row'
            borderRadius={3}
            sx={{ background: 'white', boxShadow: '2px 4px 20px 0px rgba(0, 0, 0, 0.05)' }}>
            <Grid item xs={4} display='flex' alignContent='center' alignItems='center'>
              <img
                src={city}
                alt={city}
                height='200px'
                width='max-content'
                style={{
                  marginLeft: 15,
                  marginTop: -8,
                }}
              />
            </Grid>

            <Grid item xs={8} display='flex' flexDirection='column' justifyContent='space-between' gap={3} sx={{ p: 5 }}>
              <Typography variant='h6'>La méthode Time To Beem</Typography>
              <Typography variant='body1'>
                Vous avez besoin d’être guidé dans le paramétrage d’une maquette numérique ? Apporter des informations à
                votre équipe ?
              </Typography>

              <Grid container item xs={6}>
                <Button
                  variant='contained'
                  sx={{ borderRadius: 3, color: 'white', backgroundColor: '#009069', maxHeight: 50 }}
                  onClick={() => navigate(pagesUrl.BEEM_SHOT_HELP_CENTER)}>
                  En savoir plus
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid container item xs={4}>
          <Box borderRadius={3} sx={{ background: '#E6F0FB', boxShadow: '2px 4px 20px 0px rgba(0, 0, 0, 0.05)' }}>
            <Grid item xs={12} display='flex' flexDirection='column' justifyContent='space-between' gap={3} sx={{ p: 5 }}>
              <Typography variant='h6'>{organization?.businessName}</Typography>
              <Typography variant='body1'>
                Accédez et modifiez aux informations de l’organisation. Invitez et gérez les collaborateurs et les contacts.
              </Typography>

              <Grid container item xs={6}>
                <Button
                  variant='contained'
                  sx={{ borderRadius: 3, color: 'white', backgroundColor: '#009069', maxHeight: 50 }}
                  onClick={() => navigate(resolveUrl(pagesUrl.MY_ORGANIZATION_PAGE, [], {}))}>
                  gérez l’organisation
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
