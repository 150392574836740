import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { FormEvent, useContext } from 'react'
import CancelButton from '../../../../components/buttons/cancel-button/cancel-button'
import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import { NumberInput } from '../../../../components/inputs/number-input/number-input'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { TextInputTypography } from '../../../../components/typography/textInputTypography'
import { BSMaterialResultContext } from '../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext'
import { BSMaterialResult } from '../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import { BSVariant } from '../../../../core/dto/beem-shot/BSVariant/BSVariant'
import { stringToNumber } from '../../../../core/services/helper-service'

interface IProps {
  openMaterialDetail: boolean
  setOpenMaterialDetail: React.Dispatch<React.SetStateAction<boolean>>
  selectedVariant: BSVariant | undefined
  selectedBsMaterialResult: BSMaterialResult | undefined
  setSelectedBsMaterialResult: React.Dispatch<React.SetStateAction<BSMaterialResult | undefined>>
  setIsSubmiting: React.Dispatch<React.SetStateAction<boolean>>
  isSubmiting: boolean
}

export function BSMaterialInfoDialog({
  openMaterialDetail,
  setOpenMaterialDetail,
  selectedVariant,
  selectedBsMaterialResult,
  setSelectedBsMaterialResult,
  setIsSubmiting,
  isSubmiting,
}: IProps): React.JSX.Element {
  const { updateMaterialQuantities } = useContext(BSMaterialResultContext)

  function updateQuantities(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (selectedBsMaterialResult?.id && selectedVariant?.id) {
      setIsSubmiting(true)
      updateMaterialQuantities(
        selectedVariant?.id,
        selectedBsMaterialResult?.id,
        selectedBsMaterialResult?.quantity.toFixed(2)
      )
        .then(() => setOpenMaterialDetail(false))
        .finally(() => setIsSubmiting(false))
    }
  }

  return (
    <Dialog open={openMaterialDetail} maxWidth='sm' fullWidth onClose={() => setOpenMaterialDetail(false)}>
      <Box component='form' id='update-quantities' onSubmit={updateQuantities} sx={{ m: 1 }}>
        <DialogTitle>Informations du matériau</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Identifiant' />
                <DefaultTypography
                  label={selectedBsMaterialResult?.ficheId ?? ''}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Impact re2020 / surface' />
                <DefaultTypography
                  label={`${selectedBsMaterialResult?.re2020CarbonImpactPerSurface.toFixed(1) ?? '0'} kgeqCO2/m²`}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Nom du produit' />
                <DefaultTypography
                  label={selectedBsMaterialResult?.nomProduit ?? ''}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <NumberInput
                id='Quantité'
                label='Quantité'
                value={selectedBsMaterialResult?.quantity ?? 0}
                decimalScale={1}
                variant='outlined'
                handleChange={(value) => {
                  if (selectedBsMaterialResult)
                    setSelectedBsMaterialResult({ ...selectedBsMaterialResult, quantity: stringToNumber(value) })
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Unité fiche' />
                <DefaultTypography
                  label={selectedBsMaterialResult?.ficheUniteLabel ?? ''}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton action={() => setOpenMaterialDetail(false)} label='Annuler' />
          <SubmitButton label='Mettre à jour' formId='update-quantities' isSubmiting={isSubmiting} />
        </DialogActions>
      </Box>
    </Dialog>
  )
}
