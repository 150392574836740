import Settings from '@mui/icons-material/Settings'
import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { pagesUrl } from '../../core/appConstants'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../core/context/side-bar/side-bar-context'
import AccountInfosFormField from './account-info-form-field'

export function AccountPage(): React.JSX.Element {
  const location = useLocation()

  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setPagination, setIcon } = useContext(AppNavBarContext)

  useEffect(() => {
    setShowAppSide(true)
    setTitle('Mon profil')
    setIcon(<Settings />)
    setPagination([])
    setPreviousUrl(pagesUrl.HOME)
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setIcon, setPagination])

  return (
    <Box>
      <Grid container display='flex' justifyContent='space-around' alignItems='flex-start'>
        <Grid item xs={10} p={4}>
          <AccountInfosFormField />
        </Grid>
      </Grid>
    </Box>
  )
}
