import { ProjectStatusEnum } from '../../enum/projectStatusEnum'
import { SimpleInformationProjectEnum } from '../../enum/SimpleInformationProjectEnum'
import { stringToDate } from '../../services/date-service'

export class SimpleInformationsProject {
  projectId = ''
  projectName = ''
  organizationId = ''
  organizationName = ''
  status: ProjectStatusEnum | undefined
  createdByUser = ''
  createdDate?: Date
  typeProject: SimpleInformationProjectEnum | undefined

  static fromDto(data: any): SimpleInformationsProject {
    const project = new SimpleInformationsProject()

    if (data === undefined) {
      return new SimpleInformationsProject()
    }

    project.projectId = data.projectId
    project.projectName = data.projectName ?? ''
    project.organizationId = data.organizationId
    project.organizationName = data.organizationName ?? ''
    project.status = data.status
    project.createdByUser = data.createdByUser ?? ''
    project.createdDate = stringToDate(data.createdDate)
    project.typeProject = data.typeProject

    return project
  }
}
