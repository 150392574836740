import { Outlet, Route } from 'react-router-dom'
import { BSLayout } from './app/components/layout/BSLayout'
import RequireAuth from './app/components/redirect/require-auth'
import { pagesUrl } from './app/core/appConstants'
import BsModelContextProvider from './app/core/context/beem-shot/BSBimModel/BSBimModelContext'
import { BSModelFileContextProvider } from './app/core/context/beem-shot/BSBimModel/BSBimModelFileContext'
import { BSImportContextProvider } from './app/core/context/beem-shot/BSBimModel/BSBimModelImportContext'
import BSModelInvitationContextProvider from './app/core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider'
import { BSCategoriesContextProvider } from './app/core/context/beem-shot/BSCategory/BSCategoriesContext'
import { BSInputContextProvider } from './app/core/context/beem-shot/BSInputContext/BSInputContext'
import { BSItemContextProvider } from './app/core/context/beem-shot/BSItems/BSItemContext'
import { BSMaterialContextProvider } from './app/core/context/beem-shot/BSMaterialResult/BSMaterialResultContext'
import { BSProjectContextProvider } from './app/core/context/beem-shot/BSProject/BSProjectContext'
import { BsProjectListContextProvider } from './app/core/context/beem-shot/BSProject/BSProjectListContext'
import { BSVariantContextProvider } from './app/core/context/beem-shot/BSVariant/BSVariantContext'
import { CodeReferenceContextProvider } from './app/core/context/code-acv/CodeReferenceContext'
import { LotContextProvider } from './app/core/context/lot-context'
import { NavBarContextProvider } from './app/core/context/nav-bar/AppNavBarContext'
import DomainNameValidatorContextProvider from './app/core/context/organization/domain-name-validator-context'
import { OrganizationContextProvider } from './app/core/context/organization/organization-context'
import { SideBarContextProvider } from './app/core/context/side-bar/side-bar-context'
import { CodesVersion } from './app/core/enum/codesVersionEnum'
import { AccountPage } from './app/pages/account-page/account-page'
import { BSBimModelControl } from './app/pages/beem-shot/BSBimModelPage/BSBimModelControl'
import { BSInvitationConfirmationPage } from './app/pages/beem-shot/BSBimModelPage/BSInvitationConfirmationPage'
import { BSUploadPage } from './app/pages/beem-shot/BSBimModelPage/BSUploadPage'
import { BSCalculationPage } from './app/pages/beem-shot/BSCalculationPage/BSCalculationPage'
import { BSCreateCalculationPage } from './app/pages/beem-shot/BSCalculationPage/BSCreateCalculationPage'
import { BSHomePage } from './app/pages/beem-shot/BSHomePage'
import { BeemShotProjectDetailPage } from './app/pages/beem-shot/BSProjectDetailsPage/BSProjectDetailsPage'
import { MyOrganization } from './app/pages/my-organization-page/my-organization'
import { MyOfferPage } from './app/pages/offer/MyOfferPage'
import { ConfigurationHelperPage } from './app/pages/support/ConfigurationHelperPage'
import { HelperCenterPage } from './app/pages/support/HelpCenterPage'
import { TutorialHelperPage } from './app/pages/support/TutorialHelperPage'

export const BSRoutes = (
  <Route
    element={
      <RequireAuth>
        <OrganizationContextProvider>
          <BSProjectContextProvider>
            <BSVariantContextProvider>
              <SideBarContextProvider>
                <NavBarContextProvider>
                  <DomainNameValidatorContextProvider>
                    <BSLayout />
                  </DomainNameValidatorContextProvider>
                </NavBarContextProvider>
              </SideBarContextProvider>
            </BSVariantContextProvider>
          </BSProjectContextProvider>
        </OrganizationContextProvider>
      </RequireAuth>
    }>
    {/* User account */}
    <Route path={pagesUrl.ACCOUNT_PAGE} element={<AccountPage />} />
    <Route path={pagesUrl.MY_OFFER_PAGE} element={<MyOfferPage />} />
    <Route
      path={pagesUrl.MY_ORGANIZATION_PAGE}
      element={
        <DomainNameValidatorContextProvider>
          <MyOrganization />
        </DomainNameValidatorContextProvider>
      }
    />
    <Route path={pagesUrl.BEEM_SHOT_HELP_CENTER} element={<HelperCenterPage />} />
    <Route path={pagesUrl.BEEM_SHOT_CONFIGURATION_HELPER} element={<ConfigurationHelperPage />} />
    <Route
      path={pagesUrl.BEEM_SHOT_TUTORIAL_HELPER}
      element={
        <BSCategoriesContextProvider>
          <CodeReferenceContextProvider projectCodeVersion={CodesVersion.VERSION_1_6}>
            <TutorialHelperPage />
          </CodeReferenceContextProvider>
        </BSCategoriesContextProvider>
      }
    />

    <Route path={pagesUrl.MY_OFFER_PAGE} element={<MyOfferPage />} />
    {/* Beem Shot main pages */}
    <Route
      path={pagesUrl.BEEM_SHOT_HOME}
      element={
        <LotContextProvider>
          <Outlet />
        </LotContextProvider>
      }>
      <Route
        path={pagesUrl.BEEM_SHOT_HOME}
        element={
          <BsProjectListContextProvider>
            <DomainNameValidatorContextProvider>
              <BSHomePage />
            </DomainNameValidatorContextProvider>
          </BsProjectListContextProvider>
        }
      />
      <Route
        element={
          <BsModelContextProvider>
            <BSInputContextProvider>
              <Outlet />
            </BSInputContextProvider>
          </BsModelContextProvider>
        }>
        <Route
          element={
            <BSModelFileContextProvider>
              <BSItemContextProvider>
                <BSModelInvitationContextProvider>
                  <BSImportContextProvider>
                    <BSCategoriesContextProvider>
                      <Outlet />
                    </BSCategoriesContextProvider>
                  </BSImportContextProvider>
                </BSModelInvitationContextProvider>
              </BSItemContextProvider>
            </BSModelFileContextProvider>
          }>
          <Route path={pagesUrl.BEEM_SHOT_CALCUL} element={<BSCalculationPage />} />
          <Route path={pagesUrl.BEEM_SHOT_UPLOAD_PAGE} element={<BSUploadPage />} />
          <Route path={pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION} element={<BSInvitationConfirmationPage />} />
          <Route path={pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL} element={<BSBimModelControl />} />
          <Route path={pagesUrl.BEEM_SHOT_CREATE_CALCUL} element={<BSCreateCalculationPage />} />
        </Route>
        <Route
          path={pagesUrl.BEEM_SHOT_PROJECT}
          element={
            <BSMaterialContextProvider>
              <BSItemContextProvider>
                <BSCategoriesContextProvider>
                  <BeemShotProjectDetailPage />
                </BSCategoriesContextProvider>
              </BSItemContextProvider>
            </BSMaterialContextProvider>
          }
        />
      </Route>
    </Route>
  </Route>
)
