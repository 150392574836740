import React from 'react'
import { Dialog, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { PageCenter } from '../../../core/enum/help-center/helpCenterEnum'
import { HelperCenter } from './HelperCenter'
import { ConfigurationHelper } from './ConfigurationHelper'
import { TutorialHelper } from './TutorialHelper'

type IProps = {
  open: boolean
  handleClose(): void
  actualPage: PageCenter
  updateActualPage(pageCenter: PageCenter): void
}

export function HelperCenterMainDialog({ open, handleClose, actualPage, updateActualPage }: IProps): React.JSX.Element {
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      {(actualPage === PageCenter.TUTORIAL_EXEMPLE || actualPage === PageCenter.AIDE_PARAMETRAGE) && (
        <DialogTitle>
          <Grid container display='flex' alignContent='center' p={3} borderRadius={3} sx={{ background: '#070F27' }}>
            <Grid container item xs={2} display='flex' alignContent='center' alignItems='center' gap={2}>
              <ArrowBackOutlinedIcon
                fontSize='medium'
                sx={{ cursor: 'pointer', color: 'white' }}
                onClick={() => updateActualPage(PageCenter.CENTRE_AIDE)}
              />

              <Typography variant='body1' color='white'>
                Centre d'aide
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs={8}
              display='flex'
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              gap={2}>
              <Typography variant='h6' sx={{ color: 'white' }}>
                {actualPage === PageCenter.AIDE_PARAMETRAGE && 'Aide au paramétrage'}
                {actualPage === PageCenter.TUTORIAL_EXEMPLE && "Tester notre maquette d'exemple"}
              </Typography>
            </Grid>

            <Grid container item xs={2} display='flex' justifyContent='end' alignItems='center' gap={2}>
              <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}

      {actualPage === PageCenter.CENTRE_AIDE && (
        <HelperCenter updateActualPage={updateActualPage} handleClose={handleClose} />
      )}
      {actualPage === PageCenter.AIDE_PARAMETRAGE && <ConfigurationHelper />}
      {actualPage === PageCenter.TUTORIAL_EXEMPLE && <TutorialHelper />}
    </Dialog>
  )
}
