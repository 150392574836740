import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { BSMaterialResult } from '../../dto/beem-shot/BSMaterialResult/BSMaterialResult'
import { useHttp } from '../use-http'

type BimModelHook = {
  getBsMaterialResult(bsVariantId: string): Promise<BSMaterialResult[]>
  updateBsMaterialQuantities(bsVariantId: string, bsMaterialResultId: string, overridenQuantities: string): Promise<void>
  resetBsMaterialQuantities(bsVariantId: string, bsMaterialResultId: string): Promise<void>
  addBSOverridenMaterial(
    bsVariantId: string,
    bsMaterialResultId: string,
    newIniesRecordId: number,
    actualQuantities: number
  ): Promise<void>
}

export function useBSMaterialResults(): BimModelHook {
  const { get, post } = useHttp()

  return useMemo(
    () => ({
      getBsMaterialResult(bsVariantId: string): Promise<BSMaterialResult[]> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT}/material-results`, [
          {
            key: 'variantId',
            value: bsVariantId,
          },
        ]).then((response) => response.json())
      },
      updateBsMaterialQuantities(
        bsVariantId: string,
        bsMaterialResultId: string,
        overridenQuantities: string
      ): Promise<void> {
        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/update-bsmaterial-result-quantities`, {}, [
          {
            key: 'bsVariantId',
            value: bsVariantId,
          },
          {
            key: 'bsMaterialResultId',
            value: bsMaterialResultId,
          },
          {
            key: 'overridenQuantities',
            value: overridenQuantities.toString(),
          },
        ]).then(() => undefined)
      },
      resetBsMaterialQuantities(bsVariantId: string, bsMaterialResultId: string): Promise<void> {
        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/reset-bsmaterial-result-quantities`, {}, [
          {
            key: 'bsVariantId',
            value: bsVariantId,
          },
          {
            key: 'bsMaterialResultId',
            value: bsMaterialResultId,
          },
        ]).then(() => undefined)
      },
      addBSOverridenMaterial(
        bsVariantId: string,
        bsMaterialResultId: string,
        newIniesRecordId: number,
        actualQuantities: number
      ): Promise<void> {
        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/add-overriden-bsmaterial`, {}, [
          {
            key: 'bsVariantId',
            value: bsVariantId,
          },
          {
            key: 'bsMaterialResultId',
            value: bsMaterialResultId,
          },
          {
            key: 'newIniesRecordId',
            value: newIniesRecordId.toString(),
          },
          {
            key: 'actualQuantities',
            value: actualQuantities.toString(),
          },
        ]).then(() => undefined)
      },
    }),
    [get, post]
  )
}
