export enum ProjectStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export const projectStatusEnumLabels = {
  [ProjectStatusEnum.IN_PROGRESS]: projectStatusToLabel(ProjectStatusEnum.IN_PROGRESS),
  [ProjectStatusEnum.FINISHED]: projectStatusToLabel(ProjectStatusEnum.FINISHED),
  [ProjectStatusEnum.ARCHIVED]: projectStatusToLabel(ProjectStatusEnum.ARCHIVED),
  [ProjectStatusEnum.DELETED]: projectStatusToLabel(ProjectStatusEnum.DELETED),
}

export function projectStatusToLabel(status: ProjectStatusEnum | undefined): string {
  switch (status) {
    case ProjectStatusEnum.IN_PROGRESS:
      return 'En Cours'
    case ProjectStatusEnum.FINISHED:
      return 'Terminé'
    case ProjectStatusEnum.ARCHIVED:
      return 'Archivé'
    case ProjectStatusEnum.DELETED:
      return 'Supprimé'
    default:
      return 'En Cours'
  }
}
