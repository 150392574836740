import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import { Box, CircularProgress, Fade, Grid, IconButton, Pagination, Tooltip, Typography, Zoom } from '@mui/material'
import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomBottomNavigation } from '../../../../components/buttons/navigate-button/CustomBottomNavigation'
import { IconTrashX } from '../../../../components/custom-icons/icon-trash-x'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { pagesUrl } from '../../../../core/appConstants'
import { RseeDocumentListContext } from '../../../../core/context/beem-pilot/rsee/rsee-document-list-context'
import { RseeProjectContext } from '../../../../core/context/beem-pilot/rsee/rsee-project-context'
import { OrganizationContext } from '../../../../core/context/organization/organization-context'
import { RseeDocument } from '../../../../core/dto/rsee/rsee-document'
import { ProjectStatusEnum } from '../../../../core/enum/projectStatusEnum'
import { formatToFrench } from '../../../../core/services/date-service'
import { resolveUrl } from '../../../../core/services/http-service'
import { DeleteRseeProjectDialog } from '../../../project-page/rsee/rsee-document-list/components/DeleteRseeProjectDialog'
import { RseeProjectForm } from '../../rseeProject/component/RseeProjectForm'
import { RseeDocumentCard } from './RseeDocumentCard'

const ITEMS_PER_PAGE = 6

export function RseeDocumentList(): React.JSX.Element {
  const navigate = useNavigate()

  const { organization } = useContext(OrganizationContext)
  const { rseeProject } = useContext(RseeProjectContext)
  const { rseeDocumentList, isLoading } = useContext(RseeDocumentListContext)

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false)

  const [page, setPage] = useState(1)

  const startIndex = useMemo(() => (page - 1) * ITEMS_PER_PAGE, [page])
  const endIndex = useMemo(() => startIndex + ITEMS_PER_PAGE, [startIndex])

  function handleClose(): void {
    setOpenDeleteDialog(false)
    setOpenUpdateDialog(false)
  }

  function handleChangePage(event: any, value: React.SetStateAction<number>): void {
    setPage(value)
  }

  return (
    <>
      {!rseeProject ? (
        <Box height='60vh' width='100%' display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
      ) : (
        <Fade in timeout={600}>
          <Box>
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                alignContent='center'
                columnGap={0.5}
                justifyItems='center'>
                <DefaultTypography label='Crée par ' color='#070F27' fontWeight={400} fontSize='11px' lineHeight='16.5px' />
                <DefaultTypography
                  label={rseeProject?.createdByUser ?? ''}
                  color='#070F27'
                  fontWeight={500}
                  fontSize='11px'
                  lineHeight='16.5px'
                />
                <Typography variant='subtitle1' fontSize={11}>
                  {`· ${organization?.businessName} · `}
                </Typography>

                <Typography variant='subtitle1' fontSize={11} fontWeight={400} sx={{ color: '#8F8F8F' }}>
                  {` Mise à jour le ${formatToFrench(new Date(rseeProject?.lastModifiedDate))}`}
                </Typography>
              </Box>

              {rseeProject && rseeProject?.hasRight && (
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  alignContent='center'
                  columnGap={0.5}
                  justifyItems='center'
                  p={1}>
                  <Tooltip title='Modifier les informations' placement='bottom' arrow>
                    <IconButton
                      onClick={() => setOpenUpdateDialog(true)}
                      sx={{ background: '#ECF3F3', borderRadius: 2, mr: 1 }}>
                      <CreateOutlinedIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Archiver le projet' placement='bottom' arrow>
                    <IconButton onClick={() => setOpenDeleteDialog(true)} sx={{ background: '#ECF3F3', borderRadius: 2 }}>
                      {rseeProject.projectStatus === ProjectStatusEnum.IN_PROGRESS && <Inventory2OutlinedIcon />}
                      {rseeProject.projectStatus === ProjectStatusEnum.ARCHIVED && <IconTrashX />}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Box>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Grid container columnSpacing={4} alignItems='flex-start' height='100%' rowGap={3}>
                  {rseeDocumentList.length > 0 ? (
                    rseeDocumentList.slice(startIndex, endIndex).map((rseeDocument: RseeDocument, index) => (
                      <Zoom in style={{ transitionDelay: `${index * 150}ms` }} key={rseeDocument.id}>
                        <Grid item xs={12} md={4} lg={4} key={rseeDocument.id}>
                          <RseeDocumentCard key={rseeDocument.id} rseeDocument={rseeDocument} />
                        </Grid>
                      </Zoom>
                    ))
                  ) : (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      height='100%'
                      width='100%'>
                      <Typography fontSize='24px' fontWeight={600} lineHeight='36px' textAlign='center' color='#374771'>
                        Aucun résultat
                      </Typography>
                      <Typography variant='body1'>Modifier les options de recherche.</Typography>
                    </Box>
                  )}
                </Grid>
              )}
            </Box>
          </Box>
        </Fade>
      )}

      {rseeProject && !isLoading && (
        <Box
          display='flex'
          flexGrow={1}
          justifyContent='right'
          alignItems='flex-end'
          alignContent='flex-end'
          alignSelf='flex-end'>
          <Pagination
            count={Math.ceil(rseeDocumentList.length / ITEMS_PER_PAGE)}
            page={page}
            onChange={handleChangePage}
            color='primary'
          />
        </Box>
      )}

      {rseeProject?.hasRight && (
        <CustomBottomNavigation
          actionLabel='ajouter un fichier RSEE'
          actionButton={() => navigate(resolveUrl(pagesUrl.BEEM_PILOT_UPLOAD_PAGE, [rseeProject?.id, 'new']))}
        />
      )}

      <DeleteRseeProjectDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} />
      <RseeProjectForm open={openUpdateDialog} handleClose={handleClose} />
    </>
  )
}
