import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { pagesUrl } from '../../../core/appConstants'
import BSModelInvitationContextProvider from '../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider'
import {
  BSProjectContext,
  isBSBimModelOwnerUser,
  isBSMainUser,
} from '../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../core/context/beem-shot/BSVariant/BSVariantContext'
import IniesRecordContextProvider from '../../../core/context/inies-context'
import MaterialLibContextProvider from '../../../core/context/material/material-lib-context'
import MaterialLibDeclarationTypeContextProvider from '../../../core/context/material/material-lib-declaration-type-context'
import MaterialLibFilterContextProvider from '../../../core/context/material/material-lib-filter-context'
import MaterialLibSearchContextProvider from '../../../core/context/material/material-lib-search-context'
import { AppNavBarContext } from '../../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../../core/context/side-bar/side-bar-context'
import { BsProjectRoleEnum } from '../../../core/enum/bs-project-role-enum'
import { CalculStatusEnum } from '../../../core/enum/calculStatusEnum'
import { BSCalculDetailPage } from '../components/BSCalculDetailPage'
import { BSProjectDetail } from '../components/BSProjectDetails'
import { CustomTabPanel, TabPanelProps } from '../components/CustomTabPanel'
import { BSDashboard } from './BSDashboardVariantTab/BSDashboard'
import { BSDashboardVariantePage } from './BSDashboardVariantTab/BSDashboardVariantePage'

export function BeemShotProjectDetailPage(): React.JSX.Element {
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle } = useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant, bsVariants } = useContext(BSVariantContext)

  const location = useLocation()

  const [tabs, setTabs] = React.useState('BS_PROJECT')

  useEffect(() => {
    const defaultTab = location.state?.defaultTab
    if (typeof defaultTab === 'string') {
      setTabs(defaultTab)
    }
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setShowProjectStatus(true)
    setTitle(bsProject?.name ?? '')

    return () => {
      setShowProjectStatus(false)
      setTitle('BEEM SHOT')
    }
  }, [location, bsProject?.name, setPreviousUrl, setShowProjectStatus, setStepHeader, setTitle, setTypeNavBar])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide, selectedVariant])

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const tabTest = useMemo((): TabPanelProps[] => {
    const panels: TabPanelProps[] = []

    if (isBSMainUser(bsProject) || isBSBimModelOwnerUser(bsProject)) {
      panels.push(
        {
          id: 'BS_PROJECT',
          Icon: FormatListBulletedIcon,
          label: 'Général',
          chips: undefined,
          content: (
            <BSModelInvitationContextProvider>
              <BSProjectDetail handleChange={handleChange} isWritingMode={isBSMainUser(bsProject)} />
            </BSModelInvitationContextProvider>
          ),
        },
        {
          id: 'TABLEAU_DE_BORD',
          Icon: FormatListBulletedIcon,
          label: 'Tableau de bord',
          chips: undefined,
          content: <BSDashboard />,
       disabled: selectedVariant?.calculStatus !== CalculStatusEnum.READY,
             disabledText: "Vous n'avez pas encore lancé de calcul",
           },
      )
    }

    if (isBSMainUser(bsProject) && selectedVariant?.calculStatus === CalculStatusEnum.READY) {
      panels.push({
        id: 'BS_DETAIL_CALCUL',
        Icon: FormatListBulletedIcon,
        label: 'Personnalisation',
        chips: undefined,
        content: (
          <MaterialLibDeclarationTypeContextProvider>
            <MaterialLibFilterContextProvider>
              <MaterialLibSearchContextProvider>
                <MaterialLibContextProvider>
                  <IniesRecordContextProvider>
                    <BSCalculDetailPage />
                  </IniesRecordContextProvider>
                </MaterialLibContextProvider>
              </MaterialLibSearchContextProvider>
            </MaterialLibFilterContextProvider>
          </MaterialLibDeclarationTypeContextProvider>
        ),
        disabled: selectedVariant?.calculStatus !== CalculStatusEnum.READY,
        disabledText: "Vous n'avez pas encore lancé de calcul",
      })
    }

    if (isBSMainUser(bsProject)) {
      panels.push({
        id: 'BS_COMPARE_VARIANTES',
        Icon: FormatListBulletedIcon,
        label: 'Comparaison Variantes',
        chips: undefined,
        content: <BSDashboardVariantePage />,
        disabled: bsVariants.length < 2,
        disabledText: "Vous n'avez pas encore de variante",
      })
    }
    return panels
  }, [bsProject, bsVariants.length, selectedVariant?.calculStatus])

  return (
    <Box>
      {bsProject && bsProject.role === BsProjectRoleEnum.BIM_MODEL_OWNER && (
        <Box
          display='flex'
          justifyContent='center'
          sx={{
            color: 'white',
            borderRadius: 4,
            backgroundColor: '#070F27',
          }}>
          Mode lecture
        </Box>
      )}
      <CustomTabPanel panels={tabTest} tabs={tabs} handleChange={handleChange} />
    </Box>
  )
}
