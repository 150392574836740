import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { resolveUrl } from '../../../core/services/http-service'

interface IProps {
  icon: React.JSX.Element | undefined
  number: number
  moduleName: string
  backgroundColor: string
  link: string
}

export function HomeCardIndicator({ icon, number, moduleName, backgroundColor, link }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  return (
    <Box
      borderRadius={2}
      sx={{ backgroundColor, ':hover': { cursor: 'pointer', boxShadow: 10 } }}
      p={2}
      onClick={() => navigate(resolveUrl(link, [], {}))}>
      {icon}

      <Box display='flex' flexDirection='row' alignItems='center' gap={1} pl={6} pt={1}>
        <Typography variant='h3'>{number}</Typography>
        <Typography variant='body2'> projets </Typography>
      </Box>

      <Typography variant='subtitle1' pl={6}>
        {moduleName}
      </Typography>
    </Box>
  )
}
