import { Box, Modal, Typography } from '@mui/material'
import React from 'react'
import { BSMaterialResult } from '../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import IniesRecord from '../../../../core/dto/material/inies-record'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { DeclarationTypeEnum } from '../../../../core/enum/declarationTypeEnum'
import { BSMaterialCardInfo } from './BSMaterialCardInfo'

type IProps = {
  openCard: boolean
  selectedRow: IniesRecord | MaterialRecord | undefined
  reset(): void
  setSelectedRow: React.Dispatch<React.SetStateAction<IniesRecord | MaterialRecord | undefined>>
  getTypologyBackground(typologie: DeclarationTypeEnum): string
  getTypologyTextColor(typologie: DeclarationTypeEnum): string
  handleCloseMaterialCardModal(): void
  handleClose(): void
  selectedBsMaterialResult: BSMaterialResult | undefined
}

export function BSMaterialCardModal(props: Readonly<IProps>): React.JSX.Element {
  const { openCard, handleCloseMaterialCardModal, selectedRow, ...otherProps } = props

  return (
    <Modal
      open={openCard}
      onClose={handleCloseMaterialCardModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box>
        {selectedRow ? (
          <BSMaterialCardInfo
            handleCloseMaterialCardModal={handleCloseMaterialCardModal}
            selectedRow={selectedRow}
            {...otherProps}
          />
        ) : (
          <Typography>Pas de matériau sélectionné</Typography>
        )}
      </Box>
    </Modal>
  )
}
