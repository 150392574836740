import { Box, CircularProgress, Grid, Tab, Tabs } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomBottomNavigation } from '../../../components/buttons/navigate-button/CustomBottomNavigation'
import { BSSubmitDialog } from '../../../components/dialog/BSSubmitDialog'
import { pagesUrl } from '../../../core/appConstants'
import { BSModelFileContext } from '../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext'
import { BsModelInvitationContext } from '../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider'
import { BSInputContext } from '../../../core/context/beem-shot/BSInputContext/BSInputContext'
import { BSProjectContext, isBSMainUser } from '../../../core/context/beem-shot/BSProject/BSProjectContext'
import { AppNavBarContext, NavbarTypeEnum } from '../../../core/context/nav-bar/AppNavBarContext'
import { OrganizationContext } from '../../../core/context/organization/organization-context'
import { BSProjectInvitationCreationDto } from '../../../core/dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto'
import { useForm } from '../../../core/hooks/form/use-form'
import { email } from '../../../core/hooks/form/validation'
import { resolveUrl } from '../../../core/services/http-service'
import { BSBimModelInviter } from '../components/BSBimModelInviter'
import BSUploader from '../components/BSUploader'
import { MissingInfoCode } from '../components/MissingInfoCode'

interface IForm {
  projectId?: string
  userOrganizationName: string
  lastName: string
  email: string
}

function dtoToForm(): IForm {
  return {
    projectId: '',
    userOrganizationName: '',
    lastName: '',
    email: '',
  }
}

function formToDto(form: IForm, bsProjectId: string, organizationName: string): BSProjectInvitationCreationDto {
  return {
    projectId: bsProjectId,
    userOrganizationName: organizationName,
    firstName: '',
    lastName: '',
    email: form.email,
  }
}

export function BSUploadPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()
  const { bsProject } = useContext(BSProjectContext)
  const { file, isModelFileLoading, isModelFileLoaded } = useContext(BSModelFileContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { invitation, sendBsModelInvitation, isModelInvitationLoading } = useContext(BsModelInvitationContext)
  const { organization } = useContext(OrganizationContext)
  const { refreshBSInput } = useContext(BSInputContext)

  const [value, setValue] = React.useState(0)
  const [bimModelFile, setBimModelFile] = useState<File | undefined>(file)
  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)

  useEffect(() => {
    if (file) {
      setBimModelFile(file)
    }
  }, [file])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setStepHeader(0)
  }, [setPreviousUrl, setStepHeader, setTypeNavBar])

  function handleChangeTabs(event: React.SyntheticEvent, newValue: number): void {
    setValue(newValue)
  }

  function getBottomButtomLabel(): string {
    if (value === 0) return 'importer la maquette paramétrée'
    if (value === 1) return "envoyer l'invitation"
    return ''
  }

  function getBottomButtomAction(): string | undefined {
    if (value === 0) {
      return 'bsModelFormId'
    }
    if (value === 1) {
      return 'send-invitation'
    }

    return ''
  }

  const submitInvitation: (form: IForm) => Promise<any> = useCallback(
    async (form: IForm) => {
      sendBsModelInvitation(formToDto(form, bsProjectId ?? '', organization?.businessName ?? ''))
        .then(() => {
          refreshBSInput()
        })
        .then(() => {
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION, [bsProjectId, bsVariantId]))
        })
    },
    [bsProjectId, navigate, organization?.businessName, refreshBSInput, sendBsModelInvitation]
  )

  const { form, errors, handleChange, handleSubmit } = useForm(invitation, dtoToForm, [email('email')], submitInvitation)

  function cancelAction(): void {
    setIsCancelProjectOpen(true)
  }
  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }
  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_HOME, []))
  }

  console.log('a isModelFileLoaded', isModelFileLoaded)
  return (
    <Box
      display='flex'
      flexGrow={1}
      flexShrink={1}
      justifyContent='center'
      flexDirection='column'
      alignContent='top'
      alignItems='center'>
      <Box display='flex' justifyContent='center' width='50%'>
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          aria-label='Tabs to choose between importing yourself or send invitation to someone'
          variant='fullWidth'
          TabIndicatorProps={{ style: { display: 'none' } }}
          textColor='inherit'
          sx={{ my: 3, backgroundColor: 'white', borderRadius: 2, height: '1vh' }}>
          <Tab
            label='Je paramètre et importe la maquette'
            id='tab-0'
            sx={{ backgroundColor: value === 0 ? '#E6F0FB' : '', borderRadius: '15px', height: '1vh' }}
          />
          <Tab
            label='J’invite un acteur à paramétrer et importer'
            id='tab-1'
            disabled={!isBSMainUser(bsProject)}
            sx={{
              backgroundColor: value === 1 ? '#E6F0FB' : '',
              borderRadius: '15px',
              height: '1vh',
              cursor: !isBSMainUser(bsProject) ? 'not-allowed' : 'pointer',
              '&.Mui-disabled': {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
              },
            }}
          />
        </Tabs>
      </Box>

      {value === 0 && (
        <Grid container columnSpacing={2} justifyContent='center' alignItems='center' sx={{ display: 'flex' }}>
          <Grid item xs={4} columnGap={2} sx={{ display: 'flex', height: '100%' }}>
            <MissingInfoCode />
          </Grid>
          <Grid item xs={6} columnGap={2} justifyContent='center' alignItems='center' sx={{ display: 'flex' }}>
            {!isModelFileLoaded ? (
              <CircularProgress />
            ) : (
              <BSUploader bsModelFile={bimModelFile} setBsModelFile={setBimModelFile} />
            )}
          </Grid>
        </Grid>
      )}

      {value === 1 && (
        <Grid
          component='form'
          id='send-invitation'
          onSubmit={handleSubmit}
          container
          justifyContent='center'
          alignItems='center'
          sx={{ display: 'flex', height: '50vh', width: '100%' }}>
          <Grid item xs={3} />
          <Grid
            container
            item
            xs={6}
            rowGap={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              background: 'white',
              boxShadow: 2,
              borderRadius: 8,
              p: 5,
            }}>
            <BSBimModelInviter form={form} handleChange={handleChange} errors={errors} />
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
      <CustomBottomNavigation
        actionLabel={getBottomButtomLabel()}
        formId={getBottomButtomAction()}
        cancelLabel='Annuler'
        cancelAction={cancelAction}
        isSubmiting={isModelFileLoading || isModelInvitationLoading}
      />
      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText='Êtes-vous sûr ?'
        contentText={
          <>
            Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProject?.name}" ? <br />
            Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
          </>
        }
        cancelButtonLabel='Annuler'
        submitButtonLabel='Mettre en pause'
      />
    </Box>
  )
}
