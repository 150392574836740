import { Button, CircularProgress } from '@mui/material'
import React from 'react'

interface IProps {
  label: string
  formId: string
  isSubmiting: boolean
  minWidth?: string
}

export function SubmitButton({ label, formId, isSubmiting, minWidth }: IProps): React.JSX.Element {
  return !isSubmiting ? (
    <Button variant='contained' type='submit' sx={{ minWidth: { minWidth } }} form={formId}>
      {label}
    </Button>
  ) : (
    <CircularProgress />
  )
}
