import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ValidateButton } from '../../../../components/buttons/validate-button/validate-button'
import { IconBrandTelegram } from '../../../../components/custom-icons/icon-band-telegram'
import { IconTrashX } from '../../../../components/custom-icons/icon-trash-x'
import { SuccessContext } from '../../../../components/layout/success-snackbar'
import { BodyTypography } from '../../../../components/typography/body-typography'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { UnderlineTypography } from '../../../../components/typography/underline-typography'
import { pagesUrl } from '../../../../core/appConstants'
import { BsModelInvitationContext } from '../../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider'
import { BSProjectContext } from '../../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { UserContext } from '../../../../core/context/user/user-context'
import { CalculStatusEnum } from '../../../../core/enum/calculStatusEnum'
import { formatToFrench, stringToDate } from '../../../../core/services/date-service'
import { resolveUrl } from '../../../../core/services/http-service'
import { TitleBimModelCardTypography } from './TitleBimModelCardTypography'

type IProps = {
  isWritingMode: boolean
}

export function BSBimModelCard({ isWritingMode }: IProps): React.JSX.Element | null {
  const { selectedVariant } = useContext(BSVariantContext)
  const { bsProject, refreshProject } = useContext(BSProjectContext)
  const { user } = useContext(UserContext)
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { cancelBsInvitation, sendBackBsModelInvitation } = useContext(BsModelInvitationContext)

  const navigate = useNavigate()
  const { bsProjectId } = useParams()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event: any): void {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(): void {
    setAnchorEl(null)
  }

  function getBSMainUserWatingModelCard(): JSX.Element {
    return bsProject?.actorInvited ? (
      <Box>
        <Box display='flex' justifyContent='space-between' alignSelf='flex-start'>
          <TitleBimModelCardTypography label='Maquette numérique' />
          <IconButton
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>

          <Menu id='card-action-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                if (bsProject?.actorInvited?.id) {
                  sendBackBsModelInvitation(bsProject?.actorInvited?.id).then(() =>
                    openSuccessSnackbar("L'invitation a bien été renvoyé")
                  )
                }
                handleClose()
              }}>
              <IconBrandTelegram />
              Renvoyer l'invitation
            </MenuItem>
            <MenuItem
              onClick={() =>
                cancelBsInvitation(bsProject?.actorInvited?.id).then(() => {
                  refreshProject()
                })
              }>
              <IconTrashX />
              Annuler l'invitation
            </MenuItem>
          </Menu>
        </Box>

        <Box display='flex' justifyContent='center' flexDirection='column' my={3} mx={8}>
          <BodyTypography
            label='Vous avez invité un acteur à paramétrer et importer la maquette numérique sur ce projet.'
            textAlign='center'
          />
        </Box>

        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          p={2}
          my={3}
          mx={8}
          sx={{ backgroundColor: '#FFF3E0' }}>
          <BodyTypography
            label={`Invitation envoyée à ${bsProject?.actorInvited.lastName} ${bsProject?.actorInvited.firstName}  (${
              bsProject?.actorInvited.email
            }) le ${formatToFrench(stringToDate(bsProject?.actorInvited?.createdDate))}`}
            textAlign='center'
          />
        </Box>
      </Box>
    ) : (
      <>
        <TitleBimModelCardTypography label='Maquette numérique' />

        <Box display='flex' justifyContent='center' flexDirection='column' m={3} gap={[5, 3]}>
          <BodyTypography
            label='Nous vous invitons à importer la maquette numérique paramétrée. Vous devez renseigner au minimum les codes ACV Time To Beem dans les
objets modélisés avant import.'
          />
          <ValidateButton
            label='Importer la maquette paramétrée'
            action={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, selectedVariant?.id]))}
          />
          <Box display='flex' flexDirection='row' alignItems='center' alignContent='center' gap={[0, 1]}>
            <BodyTypography label='Où renseigner les codes ACV ? ' />
            <UnderlineTypography label='Accéder au centre d’aide' url='' />
          </Box>
        </Box>
      </>
    )
  }

  function getCard(): React.JSX.Element | null {
    switch (selectedVariant?.calculStatus) {
      case CalculStatusEnum.WAITING_FOR_MODEL:
        return (
          <>
            {isWritingMode && getBSMainUserWatingModelCard()}

            {!isWritingMode && (
              <>
                <TitleBimModelCardTypography label='Maquette numérique' />
                <Box display='flex' justifyContent='center' flexDirection='column' m={3} gap={[5, 3]}>
                  <BodyTypography
                    label='Nous vous invitons à importer la maquette numérique paramétrée. Vous devez renseigner au minimum les codes ACV Time To Beem dans les
     objets modélisés avant import.'
                  />
                  <ValidateButton
                    label='Importer la maquette paramétrée'
                    action={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, selectedVariant.id]))}
                  />
                  <Box display='flex' flexDirection='row' alignItems='center' alignContent='center' gap={[0, 1]}>
                    <BodyTypography label='Où renseigner les codes ACV ? ' />
                    <UnderlineTypography label='Accéder au centre d’aide' url='' />
                  </Box>
                </Box>
              </>
            )}
          </>
        )
      case CalculStatusEnum.WAITING_FOR_CONTROL:
        return (
          <>
            <TitleBimModelCardTypography label='Maquette numérique' />
            <Box display='flex' justifyContent='center' flexDirection='column' m={3} gap={[5, 3]}>
              <BodyTypography label='Nous vous invitons à contrôler la maquette numérique que vous avez choisi.' />
              <ValidateButton
                label='Contrôler la maquette paramétrée'
                action={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL, [bsProjectId, selectedVariant.id]))}
              />
              <Box display='flex' flexDirection='row' alignItems='center' alignContent='center' gap={[0, 1]}>
                <BodyTypography label='Où renseigner les codes ACV ? ' />
                <UnderlineTypography label='Accéder au centre d’aide' url='' />
              </Box>
            </Box>
          </>
        )
      case CalculStatusEnum.WAITING_FOR_CONFIGURATION:
      case CalculStatusEnum.CALCUL_IN_PROGRESS:
      case CalculStatusEnum.READY:
        return (
          <>
            <TitleBimModelCardTypography label='Maquette numérique' />
            <Box display='flex' justifyContent='center' flexDirection='column' m={3} gap={[0, 1]}>
              <Box display='flex' flexDirection='row' alignItems='center' gap={[0, 0.5]}>
                <BodyTypography label='Importée par ' />
                <DefaultTypography label={bsProject?.createdByUser ?? ''} />
              </Box>

              <Box display='flex' flexDirection='row' alignItems='center' gap={[0, 1]}>
                <BodyTypography label={user?.organizationName ?? ''} />
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                borderRadius={2}
                justifyContent='center'
                alignItems='center'
                width='50%'
                gap={[0, 1]}
                p={1}
                sx={{ background: '#fff' }}>
                <BodyTypography label='Mise à jour le ' color='#4965AC' />
                <DefaultTypography label={formatToFrench(bsProject?.lastModifiedDate)} color='#4965AC' />
              </Box>

              <Box display='flex' justifyContent='right'>
                <UnderlineTypography
                  label='Mettre à jour'
                  url={resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, selectedVariant.id])}
                />
              </Box>
            </Box>
          </>
        )
      default:
        return null
    }
  }

  return getCard()
}
