import { Outlet, Route } from 'react-router-dom'
import { BSLayout } from './app/components/layout/BSLayout'
import RequireAuth from './app/components/redirect/require-auth'
import { pagesUrl } from './app/core/appConstants'
import RseeDocumentContextProvider from './app/core/context/beem-pilot/rsee/rsee-document-context'
import { RseeDocumentListContextProvider } from './app/core/context/beem-pilot/rsee/rsee-document-list-context'
import { RseeProjectContextProvider } from './app/core/context/beem-pilot/rsee/rsee-project-context'
import { RseeProjectListContextProvider } from './app/core/context/beem-pilot/rsee/rsee-project-list-context'
import { RseeTeamProjectContextProvider } from './app/core/context/beem-pilot/rsee/rsee-team-project-context'
import { LotContextProvider } from './app/core/context/lot-context'
import { NavBarContextProvider } from './app/core/context/nav-bar/AppNavBarContext'
import { OrganizationContextProvider } from './app/core/context/organization/organization-context'
import { SideBarContextProvider } from './app/core/context/side-bar/side-bar-context'
import { RseeDocumentDashboard } from './app/pages/beem-pilot/rseeDocumentDashboard/rseeDocumentDashboard'
import { RseeHomePage } from './app/pages/beem-pilot/RseeHomePage'
import { RseeProjectDetailPage } from './app/pages/beem-pilot/rseeProjectDetail/RseeProjectDetailsPage'
import { RseeSummaryDocumentImport } from './app/pages/beem-pilot/rseeSummary/RseeSummaryDocumentImport'
import { RseeUploadPage } from './app/pages/beem-pilot/rseeUpload/RseeUploadPage'
import { ExternalDocumentDashboard } from './app/pages/project-page/rsee/external-dashboard/external-document-dashboard'

export const RseeRoutes = (
  <Route
    element={
      <OrganizationContextProvider>
        <RequireAuth>
          <RseeProjectContextProvider>
            <SideBarContextProvider>
              <NavBarContextProvider>
                <BSLayout />
              </NavBarContextProvider>
            </SideBarContextProvider>
          </RseeProjectContextProvider>
        </RequireAuth>
      </OrganizationContextProvider>
    }>
    <Route
      path={pagesUrl.BEEM_PILOT_HOME}
      element={
        <LotContextProvider>
          <Outlet />
        </LotContextProvider>
      }>
      <Route
        path={pagesUrl.BEEM_PILOT_HOME}
        element={
          <RseeProjectListContextProvider>
            <RseeHomePage />
          </RseeProjectListContextProvider>
        }
      />
      <Route
        element={
          <RseeDocumentContextProvider>
            <Outlet />
          </RseeDocumentContextProvider>
        }>
        <Route path={pagesUrl.BEEM_PILOT_UPLOAD_PAGE} element={<RseeUploadPage />} />
        <Route path={pagesUrl.BEEM_PILOT_DOCUMENT_SUMMARY_IMPORT} element={<RseeSummaryDocumentImport />} />
        <Route path={pagesUrl.BEEM_PILOT_DOCUMENT_DASHBOARD} element={<RseeDocumentDashboard />} />
      </Route>
      <Route
        path={pagesUrl.BEEM_PILOT_PROJECT}
        element={
          <RseeDocumentListContextProvider>
            <RseeTeamProjectContextProvider>
              <RseeProjectDetailPage />
            </RseeTeamProjectContextProvider>
          </RseeDocumentListContextProvider>
        }
      />
      <Route path={pagesUrl.BEEM_PILOT_DASHBOARD_EXTERNAL} element={<ExternalDocumentDashboard />} />
    </Route>
  </Route>
)
