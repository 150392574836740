import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { User } from '../../dto/user/user'
// import { getCurrentUserFromStorage } from '../../services/authentication-service'
import { SuccessContext } from '../../../components/layout/success-snackbar'
import { MyUserUpdateDto } from '../../dto/user/my-user-update-dto'
import { useUser } from '../../hooks/use-user'
import { getUserFromStorage } from '../../services/authentication-service'
import { TokenContext } from './token-context'

export const UserContext = React.createContext<UserStore>({} as UserStore)
export function UserContextProvider({ children }: Children): React.JSX.Element {
  const initialUser: User | undefined = useMemo(() => getUserFromStorage(), [])

  const { getCurrentUser, updateMyUser } = useUser()
  const openSuccessSnackbar: (message: string) => void = React.useContext(SuccessContext)
  const { cognitoUserIdRef, unsetCookieAndUserId } = React.useContext(TokenContext)

  const [user, setUser] = useState<User | undefined>(initialUser)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [isFromLogoutButton, setIsFromLogoutButton] = useState<boolean>(false)

  useEffect(() => {
    if (user === undefined) {
      localStorage.removeItem('user')
    } else {
      localStorage.setItem('user', JSON.stringify(user))
    }
  }, [user])

  useEffect(() => {
    console.log('cognitoUserIdRef was updated', cognitoUserIdRef.current)
  }, [cognitoUserIdRef.current])

  const logout = useCallback(async () => {
    await unsetCookieAndUserId()
    setUser(undefined)
  }, [unsetCookieAndUserId])

  const getCurrentUserConnexion = useCallback(
    () => {
      if (cognitoUserIdRef.current && !userLoading) {
        setUserLoading(true)
        getCurrentUser()
          .then((newUser) => {
            setUser(newUser)
          })
          .catch((reason) => {
            console.error('reason: ', reason)
            return logout()
          })
          .finally(() => setUserLoading(false))
      } else if (!userLoading) {
        setUser(undefined)
      }
    },
    // userLoading, cognitoUserIdRef
    [logout, getCurrentUser, unsetCookieAndUserId]
  )

  const updateUserContext = useCallback(
    async (userUpdateDto: MyUserUpdateDto) => {
      console.info('update user')
      try {
        setUserLoading(true)
        const updatedUser = await updateMyUser(userUpdateDto)
        setUser((prevUser) => {
          if (prevUser) {
            return {
              ...prevUser,
              givenName: updatedUser.givenName,
              email: updatedUser.email,
              familyName: updatedUser.familyName,
              phone: updatedUser.phone,
            }
          } else {
            logout()
            return undefined
          }
        })
        openSuccessSnackbar('Modification réussie !')
      } finally {
        setUserLoading(false)
      }
    },
    [logout, openSuccessSnackbar, updateMyUser]
  )

  const userStore: UserStore = useMemo(
    () => ({
      user,
      userLoading,
      updateUserContext,
      logout,
      isFromLogoutButton,
      setIsFromLogoutButton,
      getCurrentUserConnexion,
    }),
    [user, userLoading, updateUserContext, logout, isFromLogoutButton, getCurrentUserConnexion]
  )
  return <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
}

export type UserStore = {
  user: User | undefined
  userLoading: boolean
  updateUserContext(userUpdateDto: MyUserUpdateDto): void
  logout(): Promise<void>
  isFromLogoutButton: boolean
  setIsFromLogoutButton: Dispatch<SetStateAction<boolean>>
  getCurrentUserConnexion(): void
}
