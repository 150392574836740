import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../../components/miscellianous/children'
import { BSProjectCardDto } from '../../../dto/beem-shot/BSProject/BSProjectCardDto'
import { ProjectStatusEnum } from '../../../enum/projectStatusEnum'
import { useBSProject } from '../../../hooks/beem-shot/useBSProject'
import { UserContext } from '../../user/user-context'

export const BSProjectListContext = React.createContext<BSProjectListStore>({} as BSProjectListStore)

export function BsProjectListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { user } = useContext(UserContext)

  const { getBSProjects } = useBSProject()

  const [bsProjectList, setBsProjectList] = useState<BSProjectCardDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [statusFilter, setStatusFilter] = useState<ProjectStatusEnum>(ProjectStatusEnum.IN_PROGRESS)

  const fetchBsProjectCard = useCallback(
    (limit?: number) => {
      setIsLoading(true)
      getBSProjects(limit)
        .then((newBSProjectList) => {
          setBsProjectList(newBSProjectList)
        })
        .finally(() => setIsLoading(false))
    },
    [getBSProjects]
  )

  useEffect(() => {
    fetchBsProjectCard()
  }, [fetchBsProjectCard])

  const bsProjectListStore = useMemo(
    () => ({
      bsProjectList,
      setBsProjectList,
      isLoading,
      setIsLoading,
      fetchBsProjectCard,
      statusFilter,
      setStatusFilter,
    }),
    [bsProjectList, fetchBsProjectCard, isLoading, statusFilter]
  )
  return <BSProjectListContext.Provider value={bsProjectListStore}>{children}</BSProjectListContext.Provider>
}

export type BSProjectListStore = {
  bsProjectList: BSProjectCardDto[]
  setBsProjectList: Dispatch<SetStateAction<BSProjectCardDto[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  fetchBsProjectCard(limit?: number): void
  statusFilter: ProjectStatusEnum
  setStatusFilter: Dispatch<SetStateAction<ProjectStatusEnum>>
}
