import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export function IconHome(props: Readonly<IProps>): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      sx={{
        width: style?.width ? style?.width : '44',
        height: style?.height ? style?.height : '44',
        stroke: style?.color ? style?.color : 'white',
      }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-home'
        strokeWidth='1.5'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M5 12l-2 0l9 -9l9 9l-2 0' />
        <path d='M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7' />
        <path d='M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6' />
      </svg>
    </SvgIcon>
  )
}
