import { useContext, useMemo } from 'react'
import { ErrorContext } from '../../../components/layout/error-snackbar'
import { appConstants } from '../../appConstants'
import { BSInput } from '../../dto/beem-shot/BSInput/BSInput'
import { BSInputUpdateDto } from '../../dto/beem-shot/BSInput/BSInputUpdateDto'
import { BSItem } from '../../dto/beem-shot/BSItem/BSItem'
import { SpringHttpError } from '../../dto/spring-http-error'
import { resolveUrl } from '../../services/http-service'
import { useHttp } from '../use-http'

type BSInputHook = {
  getBSInput(variantId: string): Promise<BSInput>
  putBSInput(bsInputCreationDto: BSInputUpdateDto): Promise<BSInput>
  updateAllUnmodifiedBSItemsputBSInput(variantId: string): Promise<BSItem[]>
}

export function useBSInput(): BSInputHook {
  const { get, put } = useHttp()
  const openErrorSnackbar = useContext(ErrorContext)

  return useMemo(
    () => ({
      getBSInput(variantId: string): Promise<BSInput> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_INPUT, [], { variantId })).then((response) =>
          response.json()
        )
      },
      putBSInput(bsInputUpdateDto: BSInputUpdateDto): Promise<BSInput> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_INPUT, bsInputUpdateDto).then((response) => response.json())
      },
      updateAllUnmodifiedBSItemsputBSInput(bsVariantId: string): Promise<BSItem[]> {
        return put(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_UPDATE_BS_ITEMS, [bsVariantId]), {}, []).then(
          async (response: Response) => {
            if (response.status === 404) {
              const clonedResponse = response.clone()
              const err: SpringHttpError = new SpringHttpError(await clonedResponse.json())
              openErrorSnackbar(new Error(err.message))
              return []
            } else {
              return response.json()
            }
          }
        )
      },
    }),
    [get, openErrorSnackbar, put]
  )
}
