import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ErrorContext } from '../../../../components/layout/error-snackbar'
import { Children } from '../../../../components/miscellianous/children'
import { BSProjectInvitation } from '../../../dto/beem-shot/BSBimModel/BSProjectInvitation'
import { BSProjectInvitationCreationDto } from '../../../dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto'
import { useBSBimModel } from '../../../hooks/beem-shot/useBSBimModel'

export const BsModelInvitationContext = React.createContext<BsModelInvitationStore>({} as BsModelInvitationStore)

export default function BSModelInvitationContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const openErrorSnackbar = useContext(ErrorContext)
  const { sendInvitation, cancelInvitation, sendBackInvitation } = useBSBimModel()
  const { bsProjectId } = useParams()

  const [invitation, setInvitation] = useState<BSProjectInvitation>()
  const [emailUser, setEmailUser] = useState<string>('')
  const [isModelInvitationLoading, setIsModelInvitationLoading] = useState<boolean>(false)

  const sendBsModelInvitation = useCallback(
    async (bsProjectInvitationCreationDto: BSProjectInvitationCreationDto) => {
      if (bsProjectId) {
        setIsModelInvitationLoading(true)

        return sendInvitation(bsProjectInvitationCreationDto, bsProjectId)
          .then((x) => {
            setInvitation(x)
            setEmailUser(bsProjectInvitationCreationDto.email)
            return x
          })
          .finally(() => setIsModelInvitationLoading(false))
      }
      return Promise.resolve()
    },
    [bsProjectId, sendInvitation]
  )

  const sendBackBsModelInvitation = useCallback(
    async (bsInvitationId: string) => {
      if (bsProjectId) {
        setIsModelInvitationLoading(true)
        return sendBackInvitation(bsProjectId, bsInvitationId).finally(() => setIsModelInvitationLoading(false))
      }
      return Promise.resolve()
    },
    [bsProjectId, sendBackInvitation]
  )

  const cancelBsInvitation = useCallback(
    (invitationId: string | undefined) => {
      if (invitationId) {
        setIsModelInvitationLoading(true)
        return cancelInvitation(invitationId).finally(() => setIsModelInvitationLoading(false))
      } else {
        openErrorSnackbar(new Error("Aucune invitation n'a été envoyé"))
      }
      return Promise.resolve()
    },
    [cancelInvitation, invitation]
  )

  const bsModelInvitationStore: BsModelInvitationStore = useMemo(
    () => ({
      invitation,
      sendBsModelInvitation,
      emailUser,
      cancelBsInvitation,
      isModelInvitationLoading,
      setIsModelInvitationLoading,
      sendBackBsModelInvitation,
    }),
    [invitation, sendBsModelInvitation, emailUser, cancelBsInvitation, isModelInvitationLoading, sendBackBsModelInvitation]
  )
  return <BsModelInvitationContext.Provider value={bsModelInvitationStore}>{children}</BsModelInvitationContext.Provider>
}

export type BsModelInvitationStore = {
  invitation: BSProjectInvitation | undefined
  sendBsModelInvitation: (
    bsProjectInvitationCreationDto: BSProjectInvitationCreationDto
  ) => Promise<BSProjectInvitation | void>
  emailUser: string
  cancelBsInvitation: (invitationId: string | undefined) => Promise<void>
  isModelInvitationLoading: boolean
  setIsModelInvitationLoading: React.Dispatch<React.SetStateAction<boolean>>
  sendBackBsModelInvitation(bsInvitationId: string): Promise<void>
}
