import { LockOutlined } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, FormEvent, useContext, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { pagesUrl } from '../../../core/appConstants'
import { TokenContext } from '../../../core/context/user/token-context'
import { UserContext } from '../../../core/context/user/user-context'
import { useUser } from '../../../core/hooks/use-user'
import { getEnvProperty, PROPERTIES } from '../../../core/services/environment-service'

export default function SignInPage(): React.JSX.Element {
  const { getTokenFromCognito } = useContext(TokenContext)
  const { userLoading, getCurrentUserConnexion } = useContext(UserContext)
  const [credentials, setCredentials] = useState({ name: '', password: '' })
  const [error, setError] = useState<string>('')
  const { sendUserConnexionInformation } = useUser()

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id

    setError('')

    setCredentials({
      ...credentials,
      [name]: value,
    })
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    getTokenFromCognito(credentials.name.toLowerCase(), credentials.password)
      .then(() => {
        sendUserConnexionInformation()
      })
      .then(() => {
        getCurrentUserConnexion()
      })
      .catch((e: any) => {
        switch (e.code) {
          case 'Username should be either an email or a phone number.':
          case 'UserNotConfirmedException':
            setError(
              `Il faut confirmer cet utilisateur. Aller sur : ${getEnvProperty(PROPERTIES.REACT_APP_BASE_URL)}${
                pagesUrl.CONFIRM_PAGE
              }`
            )
            break
          case 'NotAuthorizedException':
            setError('Login ou mot de passe incorrect')
            break
          case 'UserNotFoundException':
            setError('Login ou mot de passe incorrect')
            break
          default:
            setError('Erreur lors de la connection')
            console.error('Code inconnu:', e)
        }
      })
  }

  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Se Connecter
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            id='name'
            margin='normal'
            required
            fullWidth
            label='Email'
            value={credentials.name}
            onChange={handleChange}
            error={error !== ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id='password'
            margin='normal'
            type='password'
            required
            fullWidth
            label='Mot de passe'
            value={credentials.password}
            onChange={handleChange}
            error={error !== ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormHelperText error>{error}</FormHelperText>
          {!userLoading ? (
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              Se Connecter
            </Button>
          ) : (
            <CircularProgress />
          )}
          <Grid container>
            <Grid item xs={6}>
              <Link component={RouterLink} to={pagesUrl.FORGOT_PWD_PAGE} variant='body2'>
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Link component={RouterLink} to={pagesUrl.SIGN_UP_PAGE} variant='body2'>
                Créer un compte
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant='caption'>
            Version: {process.env.REACT_APP_VERSION}-{getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT)}
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}
