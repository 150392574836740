import React, { useEffect, useMemo, useState } from 'react'
import { Children } from '../../components/miscellianous/children'
import Lot from '../dto/lots/lot'
import SousLot from '../dto/lots/sous-lot'
import { useCode } from '../hooks/use-code'

export const LotContext = React.createContext<LotStore>({} as LotStore)

export function LotContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [lots, setLots] = useState<Lot[]>([])
  const [sousLots, setSousLots] = useState<SousLot[]>([])
  const [enumToLot, setEnumToLot] = useState<EnumToLot>({})
  const [enumToSousLot, setEnumToSousLot] = useState<EnumToSousLot>({})
  const { fetchLot, fetchSousLot } = useCode()

  useEffect(() => {
    fetchLot().then((newLotList) => {
      // Add an empty value to be used in <Autocomplete/>
      setLots([...newLotList])
      setEnumToLot(lotToMap(newLotList))
    })
  }, [fetchLot])

  useEffect(() => {
    fetchSousLot().then((newList) => {
      // Add an empty value to be used in <Autocomplete/>
      setSousLots([...newList])
      setEnumToSousLot(sousLotToMap(newList))
    })
  }, [fetchSousLot])

  const lotStore = useMemo(() => ({ lots, sousLots, enumToLot, enumToSousLot, setSousLots }), [lots, sousLots])
  return <LotContext.Provider value={lotStore}>{children}</LotContext.Provider>
}

function lotToMap(array: Lot[]): EnumToLot {
  const codeToLabel: EnumToLot = {}
  array.forEach((item) => {
    codeToLabel[item.name] = item
  })
  return codeToLabel
}

export function sousLotToMap(array: SousLot[]): EnumToSousLot {
  const codeToObject: EnumToSousLot = {}
  array.forEach((item) => {
    codeToObject[item.name] = item
  })
  return codeToObject
}

type LotStore = {
  lots: Lot[]
  sousLots: SousLot[]
  enumToLot: EnumToLot
  enumToSousLot: EnumToSousLot
  setSousLots: React.Dispatch<React.SetStateAction<SousLot[]>>
}

export interface EnumToLot {
  [key: string]: Lot
}

export interface EnumToSousLot {
  [key: string]: SousLot
}
