import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconPencil } from '../../../components/custom-icons/icon-pencil'
import SelectInput from '../../../components/inputs/select-input/select-input'
import { useTutorial } from '../../../core/hooks/bim-model/use-tutorial'
import {
  archicadVersions,
  revitVersions,
  videosData,
} from '../../project-page/bim-model/tutorial-page/tutorial-section/tutorial-bim-video'
import DigitalModelVideoCard from '../../project-page/bim-model/tutorial-page/tutorial-section/project-bim-model-video-card'

export enum SoftwareName {
  REVIT = 'REVIT',
  ARCHICAD = 'ARCHICAD',
}

type IProps = {
  software: SoftwareName | undefined
}

export function DownloadSharedSettings({ software }: Readonly<IProps>): React.JSX.Element {
  const { fetchSoftwareDoc } = useTutorial()

  const [loading, setLoading] = useState<boolean>(false)
  const [version, setVersion] = useState<string>('V2023')

  useEffect(() => {
    if (software === SoftwareName.ARCHICAD) {
      setVersion(archicadVersions[0].value ?? '')
    } else if (software === SoftwareName.REVIT) {
      setVersion(revitVersions[0].value ?? '')
    }
    setVersion('')
  }, [software])

  function handleChange(actualVersion: string): void {
    setVersion(actualVersion)
  }

  async function handleDownloadSoftware(): Promise<void> {
    setLoading(true)
    if (software) {
      const file = await fetchSoftwareDoc(software, version).finally(() => setLoading(false))
      if (file) {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = file.name
        element.click()
      }
    }
  }

  return (
    <Box display='flex' flexDirection='column' borderRadius={4} gap={3} sx={{ background: '#E6F0FB', p: 3, width: '100%' }}>
      <Box display='flex' gap={1}>
        <IconPencil />
        <Typography variant='subtitle1'>Téléchargez les paramètres partagés et jeu de propriétés</Typography>
      </Box>

      <Typography>Sélectionnez la version de votre logiciel</Typography>

      <Box display='flex' flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
        <SelectInput
          id='version'
          label='Version'
          mode='direct'
          handleChange={(e) => handleChange(e)}
          isBeemShot
          selectedOption={version}
          options={
            software === SoftwareName.ARCHICAD
              ? archicadVersions.map((x) => ({
                  value: x.value,
                  label: `${SoftwareName.ARCHICAD} ${x.label}`,
                }))
              : revitVersions.map((x) => ({
                  value: x.value,
                  label: `${SoftwareName.REVIT} ${x.label}`,
                }))
          }
        />

        <Button variant='contained' onClick={() => handleDownloadSoftware()}>
          Télécharger au format .ZIP
        </Button>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        borderRadius={4}
        gap={1}
        width='100%'
        sx={{ background: '#F5F8F8', border: 3, p: 3, borderColor: '#ECF3F3' }}>
        <Typography variant='subtitle1'>Tutoriels vidéo</Typography>

        {videosData[software === SoftwareName.REVIT ? 1 : 2].map((item) => (
          <Accordion
            disableGutters
            square
            key={item.name}
            sx={{
              borderRadius: 4,
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                border: 0,
              }}>
              <Typography variant='subtitle2'>{item.title}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid item sm={12} key={item.title}>
                <DigitalModelVideoCard title='' video={item.name} thumbnail={item.thumbnail} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}
