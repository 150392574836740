import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ViewerIFC from '../../../../components/ifc-displayer/ViewerIFC'
import { CachingHelper } from '../../../../components/ifc-displayer/helpers/CachingHelper'
import { SuccessContext } from '../../../../components/layout/success-snackbar'
import { Children } from '../../../../components/miscellianous/children'
import { BSBimModel } from '../../../dto/beem-shot/BSBimModel/BSBimModel'

import { BSBimModelCreateOrUpdate } from '../../../dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate'
import { CodeExtrait } from '../../../dto/code-extrait/code-extrait'
import { CodeExtraitCreation } from '../../../dto/code-extrait/code-extrait-creation'
import { useBSBimModel } from '../../../hooks/beem-shot/useBSBimModel'
import { useBSCode } from '../../../hooks/beem-shot/useBSCode'
import { zipFile } from '../../../services/zip-services'
import { BSVariantContext } from '../BSVariant/BSVariantContext'
import { BsModelContext } from './BSBimModelContext'
import { BSModelFileContext } from './BSBimModelFileContext'

export const BSModelImportContext = React.createContext<BSImportContextStore>({} as BSImportContextStore)

export function BSImportContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { postCodeExtrait } = useBSCode()
  const { sendBsBimModel, sendBsFile } = useBSBimModel()

  /** all types required by the app with all their element ids */
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { file, setFile, isCompressed, setIsModelfileLoading } = useContext(BSModelFileContext)
  const { setBsBimModel } = useContext(BsModelContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const [typesToElementIdsMap, setTypesToElementIdsMap] = useState<Map<number, number[]>>(new Map())
  const [viewer, setViewer] = useState<ViewerIFC | undefined>(undefined)
  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [isSubmittingExtractedCode, setIsSubmittingExtractedCode] = useState<boolean>(false)

  const isFormUpdateRef = useRef(false)
  const isModelUpdateRef = useRef(false)
  const isVersionUpdateRef = useRef(false)

  useEffect(() => {
    if (codesExtraits.length && viewer) {
      viewer?.manager.subsetsManager.setCodeColors(viewer.modelId ?? 0, codesExtraits, typesToElementIdsMap)
    }
  }, [codesExtraits, typesToElementIdsMap, viewer])

  const sendOnlyBimModelForm = useCallback(
    (bsModelForm: BSBimModelCreateOrUpdate, bsVariantId: string): Promise<void> =>
      sendBsBimModel(bsModelForm, bsVariantId)
        .then((response: BSBimModel) => {
          if (response.id === undefined) {
            throw new Error("Le retour du serveur ne contient pas d'id bimModel")
          }
          setBsBimModel(response)
          return response.id
        })
        .then(() => {
          openSuccessSnackbar('Les informations ont été mises à jour avec succès')
        })
        .finally(() => {
          setIsSubmitting(false)
        }),
    [openSuccessSnackbar, sendBsBimModel, setBsBimModel]
  )

  const sendBimModelAndFile = useCallback(
    (fileToSend: File, bsModelDto: BSBimModelCreateOrUpdate): Promise<void> => {
      if (!selectedVariant?.id) {
        return Promise.resolve()
      }

      setIsModelfileLoading(true)
      return sendBsBimModel(bsModelDto, selectedVariant.id)
        .then((response: BSBimModel) => {
          if (response.id === undefined) {
            throw new Error("Le retour du serveur ne contient pas d'id bimModel")
          }
          setBsBimModel(response)
          return response.id
        })
        .then((bimModelId) => {
          if (file?.name?.endsWith('.ifczip') && !isCompressed) {
            zipFile(fileToSend, setFile)
          }
          return sendBsFile(fileToSend, bimModelId)
        })
        .then(() => {
          setFile(fileToSend)
          CachingHelper.cacheFile(fileToSend)
          openSuccessSnackbar('Le modèle et ses informations ont été chargés avec succès')
        })
        .finally(() => {
          setIsSubmitting(false)
          setIsModelfileLoading(false)
          isVersionUpdateRef.current = true
        })
    },
    [
      file?.name,
      isCompressed,
      openSuccessSnackbar,
      selectedVariant?.id,
      sendBsBimModel,
      sendBsFile,
      setBsBimModel,
      setFile,
      setIsModelfileLoading,
    ]
  )

  const sendBimModelInformation = useCallback(
    async (bsModelFile: File, bsModelCreateOrUpdateDto: BSBimModelCreateOrUpdate) => {
      if (!selectedVariant?.id) {
        return Promise.resolve()
      }

      setIsSubmitting(true)
      // set the bimModel file name and hash
      if (bsModelCreateOrUpdateDto) {
        bsModelCreateOrUpdateDto.fileName = bsModelFile.name
        // set the cache and also cache the file
        bsModelCreateOrUpdateDto.modelHashFile = await CachingHelper.cacheFile(bsModelFile)
      }

      if (isModelUpdateRef.current) {
        return sendBimModelAndFile(bsModelFile, bsModelCreateOrUpdateDto)
      } else if (isFormUpdateRef.current) {
        return sendOnlyBimModelForm(bsModelCreateOrUpdateDto, selectedVariant.id)
      } else {
        setIsSubmitting(false)
        return Promise.resolve()
      }
    },
    [selectedVariant?.id, sendBimModelAndFile, sendOnlyBimModelForm]
  )

  const createCodeExtraits = useCallback(() => {
    const codesExtraitsCreation: CodeExtraitCreation[] = codesExtraits
      .filter((codeExtrait) => codeExtrait.errors.length === 0)
      .map((codeExtrait) => CodeExtraitCreation.fromCodeExtrait(codeExtrait))

    if (selectedVariant?.id) {
      setIsSubmittingExtractedCode(true)
      return postCodeExtrait(selectedVariant.id, codesExtraitsCreation, isVersionUpdateRef.current).finally(() => {
        setIsSubmittingExtractedCode(false)
        isVersionUpdateRef.current = false
      })
    }
    return Promise.resolve()
  }, [selectedVariant?.id, codesExtraits, postCodeExtrait])

  const store: BSImportContextStore = useMemo(
    () => ({
      typesToElementIdsMap,
      setTypesToElementIdsMap,
      viewer,
      setViewer,
      codesExtraits,
      setCodesExtraits,
      sendBimModelInformation,
      createCodeExtraits,
      setIsSubmitting,
      isSubmitting,
      isSubmittingExtractedCode,
      isFormUpdateRef,
      isModelUpdateRef,
    }),
    [
      codesExtraits,
      createCodeExtraits,
      isSubmitting,
      isSubmittingExtractedCode,
      sendBimModelInformation,
      typesToElementIdsMap,
      viewer,
    ]
  )

  return <BSModelImportContext.Provider value={store}>{children}</BSModelImportContext.Provider>
}

export type BSImportContextStore = {
  typesToElementIdsMap: Map<number, number[]>
  setTypesToElementIdsMap: Dispatch<SetStateAction<Map<number, number[]>>>
  viewer: ViewerIFC | undefined
  setViewer: Dispatch<SetStateAction<ViewerIFC | undefined>>
  codesExtraits: CodeExtrait[]
  setCodesExtraits: Dispatch<SetStateAction<CodeExtrait[]>>
  sendBimModelInformation(
    bsModelFile: File,
    bsModelForm: BSBimModelCreateOrUpdate,
    isModelUpdated: boolean,
    isFormUpdate: boolean
  ): Promise<void>
  createCodeExtraits(): Promise<void>
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  isSubmitting: boolean
  isSubmittingExtractedCode: boolean
  isFormUpdateRef: React.MutableRefObject<boolean>
  isModelUpdateRef: React.MutableRefObject<boolean>
}
