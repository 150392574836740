import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { IconPencil } from '../../../components/custom-icons/icon-pencil'
import { useTutorial } from '../../../core/hooks/bim-model/use-tutorial'

export function DownloadRevitPlugin(): React.JSX.Element {
  const { fetchRevitPlugin } = useTutorial()
  const [isDownloading, setIsDownloading] = useState(false)

  async function handleDownloadRevitPlugin(): Promise<void> {
    try {
      setIsDownloading(true)
      const file = await fetchRevitPlugin()
      if (file) {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = file.name
        element.click()
      }
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Box display='flex' flexDirection='column' borderRadius={4} gap={3} sx={{ background: '#C9FFE8', p: 3 }}>
      <Box display='flex' gap={1}>
        <IconPencil />
        <Typography variant='subtitle1'>Installer le plugin REVIT</Typography>
      </Box>

      <Typography variant='body1'>
        Repoussez les limites de votre travail avec ce plugin gratuit qui vous fera gagner du temps
      </Typography>

      {isDownloading ? (
        <CircularProgress />
      ) : (
        <Button onClick={handleDownloadRevitPlugin} variant='contained' sx={{ width: '40%', borderRadius: 3 }}>
          Installer le plugin
        </Button>
      )}
    </Box>
  )
}
