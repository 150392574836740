import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { CachingHelper } from '../../../../components/ifc-displayer/helpers/CachingHelper'
import { Children } from '../../../../components/miscellianous/children'
import { useBSBimModel } from '../../../hooks/beem-shot/useBSBimModel'
import { BSProjectContext } from '../BSProject/BSProjectContext'
import { BsModelContext } from './BSBimModelContext'
import { BSVariantContext } from '../BSVariant/BSVariantContext'

export type BSModelFileStore = {
  file?: File
  isModelFileLoading: boolean
  isModelFileLoaded: boolean
  setIsModelfileLoading: Dispatch<SetStateAction<boolean>>
  isCompressed: boolean
  setIsCompressed: React.Dispatch<React.SetStateAction<boolean>>
  setFile: Dispatch<SetStateAction<File | undefined>>
}
export const BSModelFileContext = React.createContext<BSModelFileStore>({} as BSModelFileStore)

export function BSModelFileContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { bsBimModel } = useContext(BsModelContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const { fetchBSBimModelFile } = useBSBimModel()

  const [isCompressed, setIsCompressed] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [isModelFileLoading, setIsModelFileLoading] = useState<boolean>(false)
  const [isModelFileLoaded, setIsModelFileLoaded] = useState<boolean>(false)

  useEffect(
    () => {
      console.log('isModelFileLoading', isModelFileLoading)
      if (!isModelFileLoading) {
        console.log('bsProject?.id', bsProject?.id)
        console.log('bsBimModel.modelHashFile', bsBimModel.modelHashFile)
        if (bsProject?.id && bsBimModel.modelHashFile) {
          // fetch the bim model information from the server so we know the file name and hash
          setIsModelFileLoading(true)
          // get the cached file if it exists
          CachingHelper.getCachedFile(bsBimModel.modelHashFile, bsBimModel.fileName)
            .then((cach) => {
              if (cach) {
                console.info('Get file from cache')
                // present the cached file
                setFile(cach)
                setIsModelFileLoading(false)
                setIsModelFileLoaded(true)
              } else {
                console.info('Download file from server')
                // fetch the file from the server because we don't have it in the cache
                if (!selectedVariant?.id) {
                  return
                }
                fetchBSBimModelFile(selectedVariant.id).then(async (blob) => {
                  if (blob) {
                    // create a file from the blob and cache it
                    const fileName =
                      bsBimModel.fileName && bsBimModel.fileName.length > 0 ? bsBimModel.fileName : 'Model.ifc'
                    const newFile = new File([blob], fileName, { type: 'application/ifc' })
                    CachingHelper.cacheFile(newFile)
                    // set the file in the context
                    setFile(newFile)
                    setIsModelFileLoading(false)
                  } else {
                    setIsModelFileLoading(false)
                  }
                })
              }
            })
            .then(() => console.info('End of Downloading'))
            .finally(() => {
              setIsModelFileLoaded(true)
            })
        } else {
          setFile(undefined)
          setIsModelFileLoaded(true)
        }
      }
    },
    // isModelFileLoading
    [bsProject, bsBimModel, fetchBSBimModelFile]
  )

  useEffect(() => {
    if (file?.name?.endsWith('.ifczip')) {
      setIsCompressed(true)
    }
  }, [file])

  const fileStore: BSModelFileStore = useMemo(
    () => ({
      file,
      setFile,
      isModelFileLoading,
      isModelFileLoaded,
      setIsModelfileLoading: setIsModelFileLoading,
      isCompressed,
      setIsCompressed,
    }),
    [file, isCompressed, isModelFileLoaded, isModelFileLoading]
  )

  return <BSModelFileContext.Provider value={fileStore}>{children}</BSModelFileContext.Provider>
}
