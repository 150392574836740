import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { TableauDashboard } from '../../../../components/tableau-dashboards/tableau-dashboard'
import { PROJECT_ID_PARAMETER_NAME } from '../../../../core/services/tableau-extensions-service'
import { TableauViz } from '../../../../core/services/tableau-service'
import { useBSProject } from '../../../../core/hooks/beem-shot/useBSProject'
import { BSProjectContext } from '../../../../core/context/beem-shot/BSProject/BSProjectContext'
import { getEnvProperty, PROPERTIES } from '../../../../core/services/environment-service'

export function BSDashboardVariantePage(): React.JSX.Element {
  const { fetchBSVarianteDashboardUrl } = useBSProject()

  const { bsProject } = useContext(BSProjectContext)

  const [dashboardUrl, setDashboardUrl] = useState<string | undefined>()
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  const viz: React.MutableRefObject<any> = useRef<any>(new TableauViz())
  const environment: string = useMemo(() => getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT), [])

  useEffect(() => {
    if (bsProject) {
      fetchBSVarianteDashboardUrl().then((responseDashboardUrl) => {
        setDashboardUrl(responseDashboardUrl)
      })
    }
  }, [bsProject, fetchBSVarianteDashboardUrl])

  return dashboardUrl && bsProject?.id ? (
    <TableauDashboard
      dashboardUrl={dashboardUrl}
      isDataReady
      tableauParameter={[
        { name: PROJECT_ID_PARAMETER_NAME, value: [environment === 'local' ? '66b4c3f3d067cd4c4d7a0660' : bsProject.id] },
      ]}
      setIsInitialized={setIsInitialized}
      vizRef={viz}
      filterToAdd={[]}
    />
  ) : (
    <CircularProgress />
  )
}
