import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { CodeExtrait } from '../../../../core/dto/code-extrait/code-extrait'

type iProps = {
  codeExtrait?: CodeExtrait
  codeTitle: string
  codeDescription?: string
  codeColor: string
  highlightBorder?: boolean
  handleClickAcv?: any
  numberElements?: number
  showBorder?: boolean
}
export function CodeDisplayer({
  codeExtrait,
  codeTitle,
  codeDescription,
  codeColor,
  highlightBorder,
  handleClickAcv,
  numberElements,
  showBorder = true,
}: Readonly<iProps>): React.JSX.Element {
  return (
    <Box width='100%' borderRadius={3} sx={{ background: '#E6F0FB', cursor: 'pointer' }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='row'
        onClick={handleClickAcv}
        sx={{
          px: showBorder ? 1 : 0,
          py: showBorder ? 0.5 : 0,
          gap: 2,
          border: showBorder ? 4 : 0,
          borderRadius: 2,
          background: '#F5F8F8',
          borderColor: highlightBorder ? '#0BE2A0' : '#ECF3F3',
        }}>
        <Box display='flex' flexDirection='row' alignContent='center' alignItems='center' gap={2}>
          {codeExtrait ? (
            <Box sx={{ background: codeColor, minWidth: 10, borderRadius: 5 }} />
          ) : (
            <Tooltip title='Valeur calculée' arrow placement='top'>
              <CalculateOutlinedIcon fontSize='medium' />
            </Tooltip>
          )}

          <Box display='flex' flexDirection='column'>
            <Typography variant='body2' fontWeight={600}>
              {`${codeTitle}  ${codeExtrait?.occurence ? ` (${codeExtrait.code}  ${codeExtrait?.occurence})` : ''} `}
            </Typography>
            <Typography variant='body2' sx={{ color: '#8398A6' }}>
              {codeDescription}
            </Typography>
          </Box>
        </Box>

        {numberElements && (
          <Box sx={{ ml: 'auto', background: 'black', borderRadius: 2, color: 'white', px: 1 }}> {numberElements}</Box>
        )}
      </Box>
    </Box>
  )
}
